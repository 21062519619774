import logo from './logo.svg';
import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  Button,
  Select,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack, HStack, VStack, StackDivider,
  Box,
  Icon,
  Center,
  Spinner,
  Spacer,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FaPeriscope, FaPhoneAlt, FaWhatsapp, FaShareAlt, FaCalendarAlt, FaInstagram, FaRegGrinHearts, FaRegSmile, FaRegMeh, FaRegMehRollingEyes, FaRegSadCry } from 'react-icons/fa';
import Carousel from './Carousel';
import axios from 'axios';
import {isIOS, isMacOs} from 'react-device-detect';
import { useOutletContext } from "react-router-dom";
import { isJwtExpired } from 'jwt-check-expiration';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import ReactArticleEditor from './react-article-editor/ReactArticleEditor';
import md5 from 'md5';
import AWS from 'aws-sdk/dist/aws-sdk-react-native';

function injectTodos(Component) {
  const InjectedTodos = function (props) {
    
    const navigate = useNavigate();
    const outletContext = useOutletContext();

    const params = useParams();
    let businessID = '';
    if(params.businessID)
      businessID = params.businessID;

    return <Component {...props} navigate={navigate} businessID={businessID} parentStates={outletContext} />;
  };
  return InjectedTodos;
}

class MyReviewList extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      jwtToken: '',
      isLoadingModalOpen: false,      
      isOpenEditingDrawer: false,
      selectedReview: null,
      isUpdating: false,
      replyToReview: '',
      reviews: [],
      repliesByOwner: [],
      isFirstTextGiven: false
    };

    AWS.config.update({
      region: 'eu-west-2',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: global.config.identityPoolId
      })
    });

    this.editor = React.createRef();
    this.defaultContent = {
      hasTitle: false,
      paragraphs:[
        {"type":1,"content":""},
      ],
    };

    this.onBrowseReview = this.onBrowseReview.bind(this);
    this.onCloseBrowseReview = this.onCloseBrowseReview.bind(this);
    this.onAddReply = this.onAddReply.bind(this);
    this.renderReview = this.renderReview.bind(this);
    this.onReplyUpdate = this.onReplyUpdate.bind(this);
    this.onTextParagraphUpdate = this.onTextParagraphUpdate.bind(this);
    this.onTextParagraphDelete = this.onTextParagraphDelete.bind(this);
    this.renderOwnerReply = this.renderOwnerReply.bind(this);
  }

  componentDidMount()
  {
    if(Cookies.get('jwtToken') != undefined)
    {
      let jwtToken = Cookies.get('jwtToken');
      console.log('token: ' + jwtToken);

      if(!isJwtExpired(jwtToken))
      {
        let businessID = this.props.businessID;
        if(businessID != '')
        {

          this.setState({
            jwtToken: jwtToken,
            isLoadingModalOpen: true
          });

          var postData = {
            businessID: businessID
          };
          let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
          };
          axios.post(global.config.lambdaGetReviewsByBusinessID, postData, axiosConfig)
          .then((res) => {
            
            var data = res['data'];
            if(data && data['result'])
            {

              axios.post(global.config.lambdaGetReviewsByOwner, postData, axiosConfig)
              .then((res2) => {

                var data2 = res2['data'];
                if(data2 && data2['result'])
                {
                  console.log(data['items']);
                  console.log(data2['items']);

                  this.setState({
                    reviews: data['items'],
                    repliesByOwner: data2['items'],
                    isLoadingModalOpen: false
                  });
                }

              }).catch((err) => {

                this.setState({
                  isLoadingModalOpen: false
                });

              });

            }

          }).catch((err) => {

            console.log('error in getting reviews...');
            this.setState({
              isLoadingModalOpen: false
            });

          });

        }
        else
        {
          window.location.href = '/';
        }
      }
      else
      {
        Cookies.remove('jwtToken');
        this.setState({jwtToken: ''});

        //this.props.navigate('/');
        window.location.href = '/';
      }
    }
    else
    {
      Cookies.remove('jwtToken');
      this.setState({jwtToken: ''});      

      this.props.navigate('/');
    }
  }

  onReplyUpdate(textValue)
  {
    this.setState({replyToReview: textValue});
  }

  onBrowseReview(review)
  {    
    this.setState({
      isOpenEditingDrawer: true,
      selectedReview: review
    });
  }

  onCloseBrowseReview()
  {
    this.setState({
      isOpenEditingDrawer: false,
      selectedReview: null
    });    
  }

  renderParagraph(paragraph, index)
  {
    return (

      <div key={index}>
        {paragraph.type == 1 &&
        <div className="row bg-light" style={{paddingTop: '1rem'}}>
          <div className="col-md-12" style={{marginBottom: '1rem'}}>
            <Text style={{whiteSpace: 'pre-line'}}>           
              {paragraph.content}
            </Text>
          </div>
        </div>
        }

        {paragraph.type == 2 &&
        <div className="row">
          <div className="col-md-12" data-aos="fade-up" style={{paddingLeft: '0', paddingRight: '0', backgroundColor: '#f1f1f1'}}>
            <img className="img-fluid " style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '1rem'}} src={global.config.reviewS3BucketPath + paragraph.image} />
          </div>
        </div>
        }
      </div>

    )          
  }

  renderOwnerReply(reviewID)
  {
    let reply = null;
    for(let i = 0; i < this.state.repliesByOwner.length; i++)
    {
      let r = this.state.repliesByOwner[i];
      if(r.replyToID == reviewID)
      {
        reply = r;
        break;
      }
    }

    return(

      
      <React.Fragment>
        
        {!reply &&
        <div>
          <Box w='100%' p='1em'>            
            <Divider />
          </Box>
          <Center p='1em'>
            <Text fontSize='xl'>Reply to this Review</Text>
          </Center>
          <Box w='100%' p='1em'>            
            <Divider />
          </Box>
                    
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="col-12">

              <ReactArticleEditor 
                editor={this.editor} 
                defaultContent={this.defaultContent}
                imageURL={global.config.reviewS3BucketPath}
                onTextParagraphUpdate={this.onTextParagraphUpdate}
                onTextParagraphDelete={this.onTextParagraphDelete}
              />

            </div>
          </div>

          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Text align='center'>
                <Button 
                  colorScheme='yellow'
                  isDisabled={!this.state.isFirstTextGiven}
                  onClick={this.onAddReply} 
                width='100%'>Submit</Button>
              </Text>            
            </div>          
          </div>
        </div>                                                                                      
        }

        {reply &&
        <div>
          <Box w='100%' p='1em'>            
            <Divider />
          </Box>
          <Box p='1em'>        
            <Alert status='info'>
              <AlertIcon />
                You have replied the following:
            </Alert>
          </Box>
          <Box w='100%' p='1em'>            
            <Divider />
          </Box>
                    
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="col-12">

              {
                JSON.parse(reply.content).paragraphs.map( (paragraph, index) =>

                  this.renderParagraph(paragraph, index)

                )
              }

            </div>
          </div>
        </div>                                                                                     
        }


      </React.Fragment>
      

    )
  }

  renderReview(review)
  {
    let reviewObject = JSON.parse(review.content);

    let firstTextParagraph = null;
    for(let i = 0; i < reviewObject.paragraphs.length; i++)
    {
      let paragraph = reviewObject.paragraphs[i];
      if(paragraph.type == 1)
      {
        firstTextParagraph = paragraph;
        break;
      }
    }

    let reply = null;
    for(let i = 0; i < this.state.repliesByOwner.length; i++)
    {
      let r = this.state.repliesByOwner[i];
      if(r.replyToID == review.id)
      {
        reply = r;
        break;
      }
    }

    return (

      <div key={review.id}>        

        {review.status == 1 && reviewObject.hasOwnProperty('thumbnail') &&
        <div className="row bg-light" style={{paddingTop: '1em', paddingBottom: '1em'}}>
          <div className="col-md-6 order-md-1 order-2" data-aos="fade-up">
            <a href="#" onClick={(e)=>{
              e.preventDefault();
              this.onBrowseReview(review);
            }}>
              <img className="img-fluid " src={global.config.thumbnailS3BucketPath + reviewObject.thumbnail} />
            </a>
          </div>
          <div className="col-md-6 order-md-12 order-1">
            <a  
            href="#" onClick={(e)=>{
              e.preventDefault();
              this.onBrowseReview(review);
            }} 
            style={{textDecoration: 'none', color: 'black'}}>
            <h3 style={{fontSize: '1.75rem', marginBottom: '0.4rem'}}>
              
              <HStack spacing='6px'>
                <Box w='auto'>
                  <ReactStars
                    value={review.score}
                    edit={false}
                    count={5}                
                    size={30}
                    color2={'#ffd700'}
                    isHalf={false}
                  />
                </Box>

                <Box w='auto'>
                    {review.score == 5 &&
                    <Icon as={FaRegGrinHearts} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />
                    }
                    {review.score == 4 &&
                    <Icon as={FaRegSmile} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />  
                    }
                    {review.score == 3 &&
                    <Icon as={FaRegMeh} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />  
                    }
                    {review.score == 2 &&
                    <Icon as={FaRegMehRollingEyes} boxSize={'1.2em'} style={{color: 'red', verticalAlign: 'middle'}} />  
                    }                      
                    {review.score == 1 &&
                    <Icon as={FaRegSadCry} boxSize={'1.2em'} style={{color: 'red', verticalAlign: 'middle'}} />  
                    }
                </Box>
                <Spacer />
                {review.hasOwnProperty('nickName') && review.nickName != '' &&
                <Box w='auto'>
                  <Text fontSize='sm'>by {review.nickName}</Text>
                </Box>
                }
              </HStack>

            </h3>
            {firstTextParagraph &&
            <Text noOfLines={[1, 2, 6]} style={{marginBottom: '0.4rem'}}>{firstTextParagraph.content}</Text>
            }
            </a>
          </div>
        </div>
        }

        {review.status == 1 && !reviewObject.hasOwnProperty('thumbnail') &&
        <div className="row bg-light" style={{paddingTop: '1em', paddingBottom: '1em'}}>
          <div className="col-md-12 order-md-12 order-1">
            <a href="#" onClick={(e)=>{
              e.preventDefault();
              this.onBrowseReview(review);
            }} 
            style={{textDecoration: 'none', color: 'black'}}>
            <h3 style={{fontSize: '1.75rem', marginBottom: '0.4rem'}}>
              
              <HStack spacing='6px'>
                <Box w='auto'>
                  <ReactStars
                    value={review.score}
                    edit={false}
                    count={5}                
                    size={30}
                    color2={'#ffd700'}
                    isHalf={false}
                  />
                </Box>

                <Box w='auto'>
                    {review.score == 5 &&
                    <Icon as={FaRegGrinHearts} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />
                    }
                    {review.score == 4 &&
                    <Icon as={FaRegSmile} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />  
                    }
                    {review.score == 3 &&
                    <Icon as={FaRegMeh} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />  
                    }
                    {review.score == 2 &&
                    <Icon as={FaRegMehRollingEyes} boxSize={'1.2em'} style={{color: 'red', verticalAlign: 'middle'}} />  
                    }                      
                    {review.score == 1 &&
                    <Icon as={FaRegSadCry} boxSize={'1.2em'} style={{color: 'red', verticalAlign: 'middle'}} />  
                    }
                </Box>
                <Spacer />
                {review.hasOwnProperty('nickName') && review.nickName != '' &&
                <Box w='auto'>
                  <Text fontSize='sm'>by {review.nickName}</Text>
                </Box>
                }
              </HStack>

            </h3>
            {firstTextParagraph &&
            <Text noOfLines={[1, 2, 6]} style={{marginBottom: '0.4rem'}}>{firstTextParagraph.content}</Text>
            }
            </a>
          </div>
        </div>
        }

        {reply &&
        <Box style={{paddingTop: '1em'}}>
          <Alert status='info'>
            <AlertIcon />
              You replied this review.
          </Alert>
        </Box>
        }

        <Box w='100%' p='1em'>            
          <Divider />
        </Box>        
      </div>

    )    
  }

  onTextParagraphUpdate(previousParagraphs, newParagraphs, index)
  {
    if(newParagraphs[index].content != '')
      this.setState({isFirstTextGiven: true});
    else
    {
      let flag = false;
      for(let i = 0; i < newParagraphs.length; i++)
      {
        let paragraph = newParagraphs[i];
        if(paragraph.type == 1 && paragraph.content != '')
        {
          flag = true;
          break;
        }
      }

      if(!flag)
        this.setState({isFirstTextGiven: false});
    }
  }

  onTextParagraphDelete(previousParagraphs, newParagraphs, index)
  {
    if(newParagraphs.length == 0)
    {
      this.setState({isFirstTextGiven: false});
    }
    else
    {
      let flag = false;
      for(let i = 0; i < newParagraphs.length; i++)
      {
        let paragraph = newParagraphs[i];
        if(paragraph.type == 1 && paragraph.content != '')
        {
          flag = true;
          break;
        }
      }

      if(!flag)
        this.setState({isFirstTextGiven: false});
    }
  }

  async onAddReply(e)
  {
    e.preventDefault();
    let content = this.editor.current.getComposition();
    
    this.setState({isUpdating: true});

    // thumbnail image won't be updated...
    let paragraphs = content.paragraphs;
    for(let i = 0; i < paragraphs.length; i++)
    {
      let paragraph = paragraphs[i];
      if(paragraph.type == 2)
      {
        if(paragraph.file)
        {
          // newly inserted image
          try
          {
            var data = await this.uploadToS3(paragraph.file, 'recipe/', true);
            console.log(data);
          }
          catch(e)
          {
            console.log('failed to upload file');
            console.log(e);

            i--;
            continue;
          }

          paragraph.image = data.fileName;

          delete paragraph.file;
          delete paragraph.base64;
          delete paragraph.defaultImages;
        }        
      }
    }
    content.paragraphs = paragraphs;
    
    var postData = {
      content: JSON.stringify(content),
      replyToID: this.state.selectedReview.id,
      businessID: this.props.businessID
    };
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': this.state.jwtToken
      }
    };

    console.log(postData);

    axios.post(global.config.lambdaAddReplyByOwner, postData, axiosConfig)
    .then((res) => {

      var data = res['data'];
      if(data && data['result'])
      {
        this.editor.current.reset();
        this.onCloseBrowseReview();

        /*
        let reply = {
          businessID: this.props.businessID,
          content: content,
          replyToID: this.state.selectedReview.id,
          status: 2
        }
        let replies = [...this.state.repliesByOwner];
        replies.push(reply);

        this.setState({
          isUpdating: false,
          repliesByOwner: replies
        });
        */

        var postData = {
          businessID: this.props.businessID
        };
        let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8'
          }
        };
        axios.post(global.config.lambdaGetReviewsByOwner, postData, axiosConfig)
        .then((res2) => {

          var data2 = res2['data'];
          if(data2 && data2['result'])
          {
            console.log(data2['items']);

            this.setState({
              isUpdating: false,
              repliesByOwner: data2['items']
            });
          }

        }).catch((err) => {

          this.setState({
            isLoadingModalOpen: false
          });

        });


      }

    })
    .catch((err) => {
      console.log("AXIOS ERROR: " + JSON.stringify(err));

      this.setState({isUpdating: false});
    });

  }

  uploadToS3(file, folder, noresize)
  {
    
    return new Promise((resolve, reject)=>{
      
      if(file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/webp')
      {
        var fileType = 'png';
        if(file.type == 'image/jpeg')
          fileType = 'jpg';
        else if(file.type == 'image/webp')
          fileType = 'webp';

        var prefix = '';
        if(noresize)
          prefix = 'noresize-';

        var fileName = prefix + md5(Date.now() + '-' + file.name) + '.' + fileType;
        var fullPath = folder + fileName;

        // Use S3 ManagedUpload class as it supports multipart uploads
        
        var upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: 'feedeasy',
            Key: fullPath,
            Body: file
          }
        });
      
        var promise = upload.promise().then(
          (data) => {
            //alert("Successfully uploaded photo.");
            data['fileName'] = fileName;
            resolve(data);            

          },
          (err) => {
            
            console.log('upload error');
            console.log(JSON.stringify(err));
            reject(err);

          }
        );
                
      }
      else
      {
        reject({reason: 'file type not supported'});
      }

    });
    
  }

  render() {
    return (

      <React.Fragment>        

        <div style={{marginTop: '100px'}}>
        </div>

        <div className="container">      

          <div className="row" style={{paddingTop: '1rem'}}>
            <div className="col-12">

                {
                  this.state.reviews.map( (review) =>

                    this.renderReview(review)

                  )
                }    

            </div>
          </div>

        </div>

        <Modal isCentered isOpen={this.state.isLoadingModalOpen}> size={'xs'}
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
          />
          <ModalContent>
            <ModalHeader></ModalHeader>            
            <ModalBody>
              <VStack spacing='24px'>
              <Center><Spinner
                  thickness='3px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='yellow.300'
                  size='xl'
                />
              </Center>
              <Center><Text color='gray.500'>Loading...</Text></Center>
              </VStack>
            </ModalBody>
            <ModalFooter>              
            </ModalFooter>
          </ModalContent>
        </Modal>        

        <Modal isCentered isOpen={this.state.isUpdating}> size={'xs'}
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
          />
          <ModalContent>
            <ModalHeader></ModalHeader>            
            <ModalBody>
              <VStack spacing='24px'>
              <Center><Spinner
                  thickness='3px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='yellow.300'
                  size='xl'
                />
              </Center>
              <Center><Text color='gray.500'>Saving...</Text></Center>
              </VStack>
            </ModalBody>
            <ModalFooter>              
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Drawer onClose={this.onCloseBrowseReview} isOpen={this.state.isOpenEditingDrawer} size={'full'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Customer's Feedback</DrawerHeader>
            <DrawerBody>
                
              <div className="container">

                {this.state.selectedReview &&
                <div className="container">

                  <div className="row" style={{paddingTop: '1em'}}>
                    <div className="col navMenu" style={{paddingTop: '1rem'}}>
                      <h2 className="text-center" style={{fontSize: '2rem'}}>
                        
                        <HStack spacing='6px'>
                          <Box w='auto'>
                            <ReactStars
                              value={this.state.selectedReview.score}
                              edit={false}
                              count={5}                
                              size={30}
                              color2={'#ffd700'}
                              isHalf={false}
                            />
                          </Box>

                          <Box w='auto'>
                              {this.state.selectedReview.score == 5 &&
                              <Icon as={FaRegGrinHearts} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />
                              }
                              {this.state.selectedReview.score == 4 &&
                              <Icon as={FaRegSmile} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />  
                              }
                              {this.state.selectedReview.score == 3 &&
                              <Icon as={FaRegMeh} boxSize={'1.2em'} style={{color: '#ecc94b', verticalAlign: 'middle'}} />  
                              }
                              {this.state.selectedReview.score == 2 &&
                              <Icon as={FaRegMehRollingEyes} boxSize={'1.2em'} style={{color: 'red', verticalAlign: 'middle'}} />  
                              }                      
                              {this.state.selectedReview.score == 1 &&
                              <Icon as={FaRegSadCry} boxSize={'1.2em'} style={{color: 'red', verticalAlign: 'middle'}} />  
                              }
                          </Box>
                          <Spacer />
                          {this.state.selectedReview.hasOwnProperty('nickName') && this.state.selectedReview.nickName != '' &&
                          <Box w='auto'>
                            <Text fontSize='sm'>by {this.state.selectedReview.nickName}</Text>
                          </Box>
                          }
                        </HStack>

                      </h2>
                    </div>
                  </div>

                  {
                    JSON.parse(this.state.selectedReview.content).paragraphs.map( (paragraph, index) =>

                      this.renderParagraph(paragraph, index)

                    )
                  }        

                  {
                    this.renderOwnerReply(this.state.selectedReview.id)
                  }


                  <Box w='100%' p='1em'>            
                    <Divider />
                  </Box>
                  <Center p='1em'>
                    <Text fontSize='xl'>End of the Page</Text>
                  </Center>
                  <Box w='100%' p='1em'>            
                    <Divider />
                  </Box>

                </div>
                }

              </div>

            </DrawerBody>
          </DrawerContent>
        </Drawer>

      </React.Fragment>

    )
  }

}

export default injectTodos(MyReviewList);