import logo from './logo.svg';
import React from 'react';


class Carousel extends React.Component {
  
  constructor(props) {
    super(props);
  }

render() {
    return (

      <React.Fragment>
      
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100 img-fluid" src={window.location.origin + "/images/slider3.jpg"} alt="First slide" />
              <div className="carousel-caption">
                <span style={{fontSize: 'xx-large', filter: 'drop-shadow(2px 4px 6px black)'}}>Asia Seafood</span>
              </div>
            </div>
          <div className="carousel-item">
            <img className="d-block w-100 img-fluid" src={window.location.origin + "/images/slider2.jpg"} alt="Second slide" />
            <div className="carousel-caption">
              <span style={{fontSize: 'xx-large', filter: 'drop-shadow(2px 4px 6px black)'}}>Toast and Coffee</span>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100 img-fluid" src={window.location.origin + "/images/slider1.jpg"} alt="Third slide" />
            <div className="carousel-caption">
              <span style={{fontSize: 'xx-large', filter: 'drop-shadow(2px 4px 6px black)'}}>Asia Fried Dishes</span>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100 img-fluid" src={window.location.origin + "/images/slider4.jpg"} alt="First slide" />
            <div className="carousel-caption">
              <span style={{fontSize: 'xx-large', filter: 'drop-shadow(2px 4px 6px black)'}}>Japanese Taste</span>
            </div>
          </div>
        </div>
          
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
          </a>
        </div>      
        

      </React.Fragment>

    )
  }

}

export default Carousel;
