import logo from './logo.svg';
import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Select,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack, HStack, VStack, StackDivider,
  Box,
  Icon,
  Center,
  Spinner,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FaPeriscope, FaPhoneAlt, FaWhatsapp, FaShareAlt, FaCalendarAlt } from 'react-icons/fa';
import Carousel from './Carousel';
import axios from 'axios';
import {isIOS, isMacOs} from 'react-device-detect';
import { useOutletContext } from "react-router-dom";
import { isJwtExpired } from 'jwt-check-expiration';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import ReactArticleEditor from './react-article-editor/ReactArticleEditor';
import md5 from 'md5';
import AWS from 'aws-sdk/dist/aws-sdk-react-native';

function injectTodos(Component) {
  const InjectedTodos = function (props) {
    
    const navigate = useNavigate();

    return <Component {...props} navigate={navigate} />;
  };
  return InjectedTodos;
}

class MyRecipeList extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      jwtToken: '',
      recipes: [],
      isLoadingModalOpen: false,      
      isOpenEditingDrawer: false,
      selectedRecipe: null,
      isUpdating: false
    };

    AWS.config.update({
      region: 'eu-west-2',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: global.config.identityPoolId
      })
    });

    this.editor = React.createRef();

    this.onEditRecipe = this.onEditRecipe.bind(this);
    this.onCloseEditRecipe = this.onCloseEditRecipe.bind(this);
    this.onUpdateRecipe = this.onUpdateRecipe.bind(this);
  }

  componentDidMount()
  {
    if(Cookies.get('jwtToken') != undefined)
    {
      let jwtToken = Cookies.get('jwtToken');
      console.log('token: ' + jwtToken);

      if(!isJwtExpired(jwtToken))
      {
        this.setState({
          jwtToken: jwtToken,
          isLoadingModalOpen: true
        });

        var postData = {};
        let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              'Authorization': jwtToken
          }
        };

        axios.post(global.config.lambdaGetRecipesByEmail, postData, axiosConfig)
        .then((res) => {

          var data = res['data'];
          if(data && data['result'])
          {
            let items = data['items'];
            this.setState({
              recipes: items,
              isLoadingModalOpen: false
            });
          }

        })
        .catch((err) => {
          console.log("AXIOS ERROR: " + JSON.stringify(err));
          
          this.setState({
            isLoadingModalOpen: false
          });
          //this.props.navigate('/');
          window.location.href = '/';
        });

      }
      else
      {
        Cookies.remove('jwtToken');
        this.setState({jwtToken: ''});

        //this.props.navigate('/');
        window.location.href = '/';
      }
    }
    else
    {
      Cookies.remove('jwtToken');
      this.setState({jwtToken: ''});      

      this.props.navigate('/');
    }
  }

  onEditRecipe(recipe)
  {    
    this.setState({
      isOpenEditingDrawer: true,
      selectedRecipe: recipe
    });
  }

  onCloseEditRecipe()
  {
    this.setState({
      isOpenEditingDrawer: false,
      selectedRecipe: null
    });    
  }

  renderRecipt(recipe)
  {
    let recipeObject = JSON.parse(recipe.content);

    let firstTextParagraph = null;
    for(let i = 0; i < recipeObject.paragraphs.length; i++)
    {
      let paragraph = recipeObject.paragraphs[i];
      if(paragraph.type == 1)
      {
        firstTextParagraph = paragraph;
        break;
      }
    }

    return (

      <div key={recipe.id}>
        <div className="row bg-light"><br /><br /></div>                  
        <div className="row bg-light">
          <div className="col-md-6 order-md-1 order-2" data-aos="fade-up">
            <a href="#" onClick={(e)=>{
              e.preventDefault();
              this.onEditRecipe(recipe);
            }}            
            >
              {recipeObject.hasOwnProperty('thumbnail') &&
              <img className="img-fluid " src={global.config.thumbnailS3BucketPath + recipeObject.thumbnail} />
              }
              {!recipeObject.hasOwnProperty('thumbnail') &&
              <img className="img-fluid " src={global.config.recipeS3BucketPath + 'default.png'} />
              }
            </a>
          </div>
          <div className="col-md-6 order-md-12 order-1">
            <a href="#" onClick={(e)=>{
              e.preventDefault();
              this.onEditRecipe(recipe);
            }}
            style={{textDecoration: 'none', color: 'black'}}            
            >
            <h3 style={{fontSize: '1.75rem', marginBottom: '0.4rem'}}>{recipeObject.title}</h3>
            {firstTextParagraph &&
            <Text noOfLines={[1, 2, 6]}>{firstTextParagraph.content}</Text>
            }
            </a>
          </div>
        </div>
      </div>

    )
  }

  async onUpdateRecipe(recipe)
  {
    let content = this.editor.current.getComposition();
    
    this.setState({isUpdating: true});

    // thumbnail image won't be updated...
    let paragraphs = content.paragraphs;
    for(let i = 0; i < paragraphs.length; i++)
    {
      let paragraph = paragraphs[i];
      if(paragraph.type == 2)
      {
        if(paragraph.file)
        {
          // newly inserted image
          try
          {
            var data = await this.uploadToS3(paragraph.file, 'recipe/', true);
            console.log(data);
          }
          catch(e)
          {
            console.log('failed to upload file');
            console.log(e);

            i--;
            continue;
          }

          paragraph.image = data.fileName;

          delete paragraph.file;
          delete paragraph.base64;
          delete paragraph.defaultImages;
        }        
      }
    }
    content.paragraphs = paragraphs;
    content.thumbnail = JSON.parse(recipe.content).thumbnail;
    //console.log(content);

    // update recipe in state
    let recipes = [...this.state.recipes];
    for(let i = 0; i < recipes.length; i++)
    {
      if(recipes[i].id == recipe.id)
      {
        recipes[i].content = JSON.stringify(content);
        this.setState({recipes: recipes});

        break;
      }
    }
    
    var postData = {
      content: JSON.stringify(content),
      id: recipe.id,
      creationTime: recipe.creationTime
    };
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': this.state.jwtToken
      }
    };

    console.log(postData);

    axios.post(global.config.lambdaAddRecipe, postData, axiosConfig)
    .then((res) => {

      var data = res['data'];
      if(data && data['result'])
      {
        this.editor.current.reset();
        this.onCloseEditRecipe();

        this.setState({isUpdating: false});
      }

    })
    .catch((err) => {
      console.log("AXIOS ERROR: " + JSON.stringify(err));

      this.setState({isUpdating: false});
    });


  }

  uploadToS3(file, folder, noresize)
  {
    
    return new Promise((resolve, reject)=>{
      
      if(file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/webp')
      {
        var fileType = 'png';
        if(file.type == 'image/jpeg')
          fileType = 'jpg';
        else if(file.type == 'image/webp')
          fileType = 'webp';

        var prefix = '';
        if(noresize)
          prefix = 'noresize-';

        var fileName = prefix + md5(Date.now() + '-' + file.name) + '.' + fileType;
        var fullPath = folder + fileName;

        // Use S3 ManagedUpload class as it supports multipart uploads
        
        var upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: 'feedeasy',
            Key: fullPath,
            Body: file
          }
        });
      
        var promise = upload.promise().then(
          (data) => {
            //alert("Successfully uploaded photo.");
            data['fileName'] = fileName;
            resolve(data);            

          },
          (err) => {
            
            console.log('upload error');
            console.log(JSON.stringify(err));
            reject(err);

          }
        );
                
      }
      else
      {
        reject({reason: 'file type not supported'});
      }

    });
    
  }

  render() {
    return (

      <React.Fragment>
        
        <div style={{marginTop: '100px'}}>
        </div>

        <div className="container">      

          <div className="row">
            <div className="col navMenu">

                {
                  this.state.recipes.map( (recipe) =>

                    this.renderRecipt(recipe)

                  )
                }    

            </div>
          </div>

        </div>

        <Modal isCentered isOpen={this.state.isLoadingModalOpen}> size={'xs'}
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
          />
          <ModalContent>
            <ModalHeader></ModalHeader>            
            <ModalBody>
              <VStack spacing='24px'>
              <Center><Spinner
                  thickness='3px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='yellow.300'
                  size='xl'
                />
              </Center>
              <Center><Text color='gray.500'>Loading...</Text></Center>
              </VStack>
            </ModalBody>
            <ModalFooter>              
            </ModalFooter>
          </ModalContent>
        </Modal>        

        <Modal isCentered isOpen={this.state.isUpdating}> size={'xs'}
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
          />
          <ModalContent>
            <ModalHeader></ModalHeader>            
            <ModalBody>
              <VStack spacing='24px'>
              <Center><Spinner
                  thickness='3px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='yellow.300'
                  size='xl'
                />
              </Center>
              <Center><Text color='gray.500'>Saving...</Text></Center>
              </VStack>
            </ModalBody>
            <ModalFooter>              
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Drawer onClose={this.onCloseEditRecipe} isOpen={this.state.isOpenEditingDrawer} size={'full'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit Recipe</DrawerHeader>
            <DrawerBody>
                
              <div className="container">

                {this.state.selectedRecipe && 
                <ReactArticleEditor editor={this.editor} defaultContent={JSON.parse(this.state.selectedRecipe.content)} imageURL={global.config.recipeS3BucketPath} />
                }

                <div className="row" style={{paddingBottom: '2rem'}}>
                  <div className="form-group col-12">
                    <Text align='center'>
                      <Button colorScheme='green' onClick={(e)=>{

                        e.preventDefault();
                        this.onUpdateRecipe(this.state.selectedRecipe);

                      }} width='100%'>Update</Button>
                    </Text>            
                  </div>          
                </div> 

              </div>

            </DrawerBody>
          </DrawerContent>
        </Drawer>

      </React.Fragment>

    )
  }

}

export default injectTodos(MyRecipeList);