import './config';
import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Outlet } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid, 
  GridItem,
  Flex,
  Box,
  Center,
  Text,
  Input,
  VStack,
  StackDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor
} from '@chakra-ui/react';
import {CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from "react-router-dom";
import { isJwtExpired } from 'jwt-check-expiration';
import axios from 'axios';

function injectTodos(Component) {
  const InjectedTodos = function (props) {
    const navigate = useNavigate();

    const params = useParams();
    let region = '';
    if(params.region)
      region = params.region;

    return <Component {...props} navigate={navigate} region={region} />;
  };
  return InjectedTodos;
}

class App extends React.Component {  


  constructor(props) {
    super(props);

    this.state = {
      isRegistrationModalOpen: false, 
      email: '', 
      password1: '',
      password2: '',
      isPasswordValid: false, 
      isEmailValid: false, 
      isPasswordValid: false, 
      isPasswordMatched: true, 
      verificationCode: '', 
      didSendCode: false,
      isGettingCode: false,

      isLoginModalOpen: false,
      isSigningIn: false,
      jwtToken: '',
      loginStatusMessage: '',

      isAboutModalOpen: false,
      region: '',
      businessID: ''
    };

    this.poolData = {
        UserPoolId: global.config.userPoolId,
        ClientId: global.config.clientId
    };
    this.userPool = new CognitoUserPool(this.poolData);

    this.onCloseRegistrationModal = this.onCloseRegistrationModal.bind(this);
    this.showRegisterModal = this.showRegisterModal.bind(this);
    this.onHandleVerificationCode = this.onHandleVerificationCode.bind(this);
    this.setPassword1 = this.setPassword1.bind(this);
    this.setPassword2 = this.setPassword2.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setVerificationCode = this.setVerificationCode.bind(this);
    this.onHandleSubmitVerificationCode = this.onHandleSubmitVerificationCode.bind(this);

    this.showLoginModal = this.showLoginModal.bind(this);
    this.onCloseLoginModal = this.onCloseLoginModal.bind(this);
    this.setLoginEmail = this.setLoginEmail.bind(this);
    this.setLoginPassword = this.setLoginPassword.bind(this);
    this.login = this.login.bind(this);
    this.signOut = this.signOut.bind(this);

    this.showAboutModal = this.showAboutModal.bind(this);
    this.onCloseABoutModal = this.onCloseABoutModal.bind(this);
  }

  componentDidMount()
  {
    if(Cookies.get('jwtToken') != undefined)
    {
      let jwtToken = Cookies.get('jwtToken');
      console.log('token: ' + jwtToken);

      if(!isJwtExpired(jwtToken))
      {        
        this.setState({jwtToken: jwtToken});
        this.setState({region: this.props.region});

        var postData = {};
        let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              'Authorization': jwtToken
          }
        };

        axios.post(global.config.lambdaGetBusinessByEmail, postData, axiosConfig)
        .then((res) => {

          var data = res['data'];
          if(data && data['result'])
          {
            let items = data['items'];
            if(items.length > 0)
            {
              //console.log(items);
              let item = items[0];
              this.setState({
                businessID: item.id
              });

              console.log(item.id);
            }
          }

        }).catch((err) => {

        });
      }
      else
      {
        Cookies.remove('jwtToken');
        this.setState({jwtToken: ''});
      }
    }
    else
    {
      Cookies.remove('jwtToken');
      this.setState({jwtToken: ''});      
    }
  }

  signOut()
  {
    var cognitoUser = this.userPool.getCurrentUser();
    if(cognitoUser)
      cognitoUser.signOut();

    this.setState({jwtToken: ''});
    this.setState({businessID: ''});

    Cookies.remove('jwtToken');

    //window.location.href = '/';
    this.props.navigate('/');
  }

  setEmail(event)
  {
    if(this.validateEmail(event.target.value))
      this.setState({isEmailValid: true});
    else
      this.setState({isEmailValid: false});

    this.setState({email: event.target.value});
  }

  setPassword1(event)
  {
    this.setState({password1: event.target.value});

    var password = event.target.value;

    if(this.state.password2 == password)
      this.setState({isPasswordMatched: true});
    else
      this.setState({isPasswordMatched: false});

    if(password.length >= 6)
      this.setState({isPasswordValid: true});
    else
      this.setState({isPasswordValid: false});
  }

  setPassword2(event)
  {
    this.setState({password2: event.target.value});
    var password = event.target.value;

    if(this.state.password1 == password)
      this.setState({isPasswordMatched: true});
    else
      this.setState({isPasswordMatched: false});
  }

  setVerificationCode(event)
  {
    this.setState({verificationCode: event.target.value});
  }

  onHandleSubmitVerificationCode()
  {

    var user = new CognitoUser({
        Username: this.state.email.replace('@', '-at-'),
        Pool: this.userPool
    });

    user.confirmRegistration(this.state.verificationCode, true, (err, result) => {
      if (!err) {
          //onSuccess(result);
          console.log('user verified');
          console.log(JSON.stringify(result));

          this.login(this.state.email, this.state.password1);

      } else {
          //onFailure(err);
          console.log('failed to verify user');
          console.log(JSON.stringify(err));          
      }
    });

  }

  showRegisterModal()
  {
    this.setState({isRegistrationModalOpen: true});
  }

  onCloseRegistrationModal()
  {
    this.setState({
      isRegistrationModalOpen: false, 
      email: '', 
      password1: '', 
      password2: '', 
      isPasswordValid: false, 
      isEmailValid: false, 
      isPasswordValid: false, 
      isPasswordMatched: true, 
      verificationCode: '', 
      didSendCode: false,
      isGettingCode: false
    });
  }

  onHandleVerificationCode()
  {
    this.setState({isGettingCode: true});

    var email = this.state.email;

    var dataEmail = {
        Name: 'email',
        Value: email
    };
    var attributeEmail = new CognitoUserAttribute(dataEmail);

    this.userPool.signUp(email.replace('@', '-at-'), this.state.password1, [attributeEmail], null,
        (err, result) => {
            
            this.setState({isGettingCode: false});

            if (!err) {

                this.setState({didSendCode: true});

                var cognitoUser = result.user;
                console.log('user name is ' + cognitoUser.getUsername());                

            } else {

                console.log(JSON.stringify(err));
                if(err['code'] == 'UsernameExistsException'){

                  var user = new CognitoUser({
                      Username: email.replace('@', '-at-'),
                      Pool: this.userPool
                  });

                  user.resendConfirmationCode((err, result) => {

                    if (err) {
                      this.setState({didSendCode: false});
                      console.log('verification code resent error...');
                      console.log(JSON.stringify(err));
                      
                    }
                    else
                    {
                      this.setState({didSendCode: true});
                      console.log('resent result: ' + JSON.stringify(result));
                    }                    

                  });

                }
                else
                {
                  this.setState({didSendCode: false});
                }

            }
        }
    );

  }

  login(email, password)
  {

    this.setState({isSigningIn: true, loginStatusMessage: ''});

    var authenticationDetails = new AuthenticationDetails({
        Username: email.replace('@', '-at-'),
        Password: password
    });

    var cognitoUser = new CognitoUser({Username: email.replace('@', '-at-'), Pool: this.userPool});
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: ()=>{

            console.log('Successfully Logged In');
            this.setState({isSigningIn: false});

            var cognitoUser = this.userPool.getCurrentUser();

            if (cognitoUser) {

              cognitoUser.getSession((err, session) => {
              
                if (err) {
                    console.log('getSession: error')
                    console.log(JSON.stringify(err));
                    this.setState({loginStatusMessage: 'Internal error!'});
                } else if (!session.isValid()) {
                    console.log('session invalid');
                    this.setState({loginStatusMessage: 'Internal error!'});
                } else {

                  console.log('JWT token: ' + session.getIdToken().getJwtToken());
                  
                  let jwtToken = session.getIdToken().getJwtToken();

                  var postData = {};
                  let axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': jwtToken
                    }
                  };

                  axios.post(global.config.lambdaGetBusinessByEmail, postData, axiosConfig)
                  .then((res) => {

                    var data = res['data'];
                    if(data && data['result'])
                    {
                      let items = data['items'];
                      if(items.length > 0)
                      {
                        //console.log(items);
                        let item = items[0];
                        this.setState({
                          businessID: item.id
                        });

                        console.log(item.id);
                      }

                      Cookies.set('jwtToken', jwtToken);                  
                      this.setState({jwtToken: jwtToken});
                      this.setState({isSigningIn: false});
                      this.setState({isLoginModalOpen: false});

                      this.props.navigate('addBusiness');
                    }

                  }).catch((err) => {

                  });

                }

              });

            }

        },
        onFailure: (err)=>{

          this.setState({isSigningIn: false});

          console.log('failed to sign in ...');
          console.log(JSON.stringify(err));

          if(err.code == 'UserNotConfirmedException')
          {
            // not yet confirmed the code
            this.setState({loginStatusMessage: 'You have registered but not yet entering your verification code. Please click "Sign Up" and enter your existing email + new password. You will obtain a new verification code and please enter it in the form to complete this.'});
          }
          else if(err.code == 'NotAuthorizedException')
          {
            // user not exists or wrong password
            this.setState({loginStatusMessage: 'Incorrect password, please try again.'});
          }


        }
    });                                                      

  }

  showLoginModal()
  {
    this.setState({isLoginModalOpen: true});
  }

  onCloseLoginModal()
  {
    this.setState({
      isLoginModalOpen: false,
      isSigningIn: false,
      jwtToken: '',
      email: '', 
      password1: '',
      loginStatusMessage: ''
    });
  }

  setLoginEmail(event)
  {
    this.setState({email: event.target.value});

    if(this.validateEmail(event.target.value))
      this.setState({isEmailValid: true});
    else
      this.setState({isEmailValid: false});    
  }

  setLoginPassword(event)
  {
    this.setState({password1: event.target.value});

    if(event.target.value.length >= 6)
      this.setState({isPasswordValid: true});
    else
      this.setState({isPasswordValid: false});    
  }

  validateEmail(email)
  {
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  showAboutModal()
  {
    this.setState({isAboutModalOpen: true});
  }

  onCloseABoutModal()
  {
    this.setState({isAboutModalOpen: false});
  }

render() {

    return (

      <React.Fragment>

        <ChakraProvider resetCSS={true}>
        
          <div id="Welcome">
            <Navbar showRegisterModal={this.showRegisterModal} showLoginModal={this.showLoginModal} showAboutModal={this.showAboutModal} signOut={this.signOut} businessID={this.state.businessID} jwtToken={this.state.jwtToken} />        
          </div>
          
          <Outlet context={this.state} />
          
          <div className="container">
            <Footer />
          </div>

          <Modal isOpen={this.state.isLoginModalOpen} onClose={this.onCloseLoginModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Sign In</ModalHeader>
              <ModalBody>

                <VStack
                  spacing={4}
                  align='stretch'
                >
                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto">
                      Email
                    </Box>
                    <Box flex='1'>
                      <Input placeholder='Sign-in email' value={this.state.email} onChange={this.setLoginEmail} />
                    </Box>
                  </Flex>

                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto">
                      Password
                    </Box>
                    <Box flex='1'>
                      <Input type={'password'} placeholder='Your password' value={this.state.password1} onChange={this.setLoginPassword}/>
                    </Box>
                  </Flex>                  

                  {this.state.loginStatusMessage != '' &&
                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto"></Box>
                    <Box flex='1'>
                      <Text fontSize='sm' style={{marginLeft: '1em', color: 'orangered'}}>{this.state.loginStatusMessage}</Text>
                    </Box>
                  </Flex>
                  }

                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto"></Box>
                    <Box flex='1'>

                      <Popover>
                        <PopoverTrigger>
                          <Text fontSize='sm' style={{width: '18rem', marginLeft: '1em', color: 'dodgerblue'}}><a href="#" style={{textDecoration: 'none'}}>Forget Password</a></Text>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader><span style={{color: '#666666'}}>Change Password</span></PopoverHeader>
                          <PopoverBody><span style={{color: '#666666'}}>Please click "Sign Up" and enter your existing email + new password. You will obtain a new verification code and please enter it in the form to complete this.</span></PopoverBody>
                        </PopoverContent>
                      </Popover>

                      
                    </Box>
                  </Flex>

                </VStack>

              </ModalBody>

              <ModalFooter>

                <Button colorScheme='blue' mr={3} onClick={(event)=>{this.login(this.state.email, this.state.password1)}} isLoading={this.state.isSigningIn} isDisabled={!this.state.isEmailValid || !this.state.isPasswordValid}>
                  Sign In
                </Button>

              </ModalFooter>

            </ModalContent>
          </Modal>


          <Modal isOpen={this.state.isRegistrationModalOpen} onClose={this.onCloseRegistrationModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Registration</ModalHeader>
              <ModalCloseButton />
              <ModalBody>

                <VStack
                  spacing={4}
                  align='stretch'
                >
                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto">
                      Email
                    </Box>
                    <Box flex='1'>
                      <Input placeholder='Your email' value={this.state.email} onChange={this.setEmail} />
                    </Box>
                  </Flex>

                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto">
                      Password
                    </Box>
                    <Box flex='1'>
                      <Input type={'password'} placeholder='Your password must have 6 or more characters in length.' value={this.state.password1} onChange={this.setPassword1}/>
                    </Box>
                  </Flex>

                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto">
                      Confirm
                    </Box>
                    <Box flex='1'>
                      <Input type={'password'} placeholder='Confirm password' value={this.state.password2} onChange={this.setPassword2} />
                    </Box>
                  </Flex>

                  {this.state.didSendCode &&
                  <Flex color='black'>
                    <Box w='20%' position="relative" top={0} right={0} left={0} bottom={0} margin="auto">
                      Code
                    </Box>
                    <Box flex='1'>
                      <Input placeholder='Please enter verification code' value={this.state.verificationCode} onChange={this.setVerificationCode}/>
                    </Box>
                  </Flex>
                  }

                </VStack>

              </ModalBody>

              <ModalFooter>

                {!this.state.didSendCode &&
                <Button colorScheme='blue' mr={3} isLoading={this.isGettingCode} onClick={this.onHandleVerificationCode} isDisabled={!this.state.isEmailValid || !this.state.isPasswordValid || !this.state.isPasswordMatched}>
                  Get a Verification Code
                </Button>
                }

                {this.state.didSendCode &&
                <Button colorScheme='blue' mr={3} onClick={this.onHandleSubmitVerificationCode}>
                  Confirm Verification Code
                </Button>
                }                

              </ModalFooter>

            </ModalContent>
          </Modal>

          <Modal onClose={this.onCloseABoutModal} size={'md'} isOpen={this.state.isAboutModalOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>ABout FeedEasy</ModalHeader>
              <ModalBody>
                This is a portal for sharing Asian taste stunning food. You are welcome to join in and promote your catering business here by signing up.              
              </ModalBody>
              <ModalFooter>
                <Button onClick={this.onCloseABoutModal}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

        </ChakraProvider>


      </React.Fragment>

    )
  }

}

export default injectTodos(App);
