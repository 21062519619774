import './config';
import logo from './logo.svg';
import React from 'react';
import {
  Button,
  Grid, 
  GridItem,
  Flex,
  Box,
  Center,
  Text,
  Input,
  Stack,
  VStack,
  StackDivider,
  Textarea,
  InputGroup,
  InputLeftElement,
  Heading,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';
import ReactImageUploadComponent from './react-images-upload/ReactImageUploadComponent';
import ImageGallery from 'react-image-gallery';
import AWS from 'aws-sdk/dist/aws-sdk-react-native';
import md5 from 'md5';
import "react-image-gallery/styles/css/image-gallery.css";
import axios from 'axios';
import { Loader } from '@googlemaps/js-api-loader';
import { isJwtExpired } from 'jwt-check-expiration';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const googleAPILoader = new Loader({
  apiKey: global.config.googleApiKey,
  version: "weekly",
  libraries: ["places"]
});

function injectTodos(Component) {
  const InjectedTodos = function (props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
  return InjectedTodos;
}

class AddEntity extends React.Component {
  
  constructor(props) {
    super(props);

    AWS.config.update({
      region: 'eu-west-2',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: global.config.identityPoolId
      })
    });

    this.state = {
      jwtToken: '',
      pictures: [],
      banners: [],
      uploadedImages: [
        //{original: 'https://feedeasy.s3.eu-west-2.amazonaws.com/images-resized/eed2c096dcb00df1b598a0e87900d39d.png', thumbnail: 'https://feedeasy.s3.eu-west-2.amazonaws.com/images-resized/eed2c096dcb00df1b598a0e87900d39d.png', isLoaded: true}, 
      ],
      uploadedBanners: [],
      isImageUploading: false,
      isImageCreatingThumbnail: false,
      imageUploadStatus: '',
      isBannerUploading: false,
      isBannerCreatingThumbnail: false,
      bannerUploadStatus: '',

      isImageDeleteAlertOpen: false,
      isBannerDeleteAlertOpen: false,

      mapCenter:{lat: 53.471787, lng: -2.294285},
      mapPlace: '',
      mapZoom: 6,

      businessName: '',
      businessAddress: '',
      region: '',
      type: 1,
      category: 0,
      displayEmail: '',
      displayPhone: '',
      whatsappLink: '',
      igLink: '',
      websiteURL: '',
      description: '',
      openingHours: '',
      deliveryDetail: '',
      doesBusinessExist: false,

      isValidationAlertOpen: false,
      errorMessageList:[
        'Please enter your business name',
        'Please enter your service introduction',
        'Please enter servicing time',
        'Please enter your contact email',
        'Please enter your business address',
        'Please provide a location on the map',
        'Please upload your chosen images first'
      ],
      errorIndex: -1,      

      isStatusAlertOpen: false,
      statusMessageList:[
        'Your entity has been added to the directory. You can edit it anytime by signing in.',
        'Your entity is sucessfully updated.',
        'Your sign-in is expired and please sign in again.'
      ],
      statusIndex: -1,

      isAdding: false,
    };

    this.bannerUploader = React.createRef();
    this.imageUploader = React.createRef();
    this.imagesGallery = React.createRef();
    this.bannersGallery = React.createRef();

    this.onDropImage = this.onDropImage.bind(this);
    this.onUploadFiles = this.onUploadFiles.bind(this);

    this.onDropBanner = this.onDropBanner.bind(this);
    this.onUploadBanner = this.onUploadBanner.bind(this);

    this.onShowDeleteGalleryImage = this.onShowDeleteGalleryImage.bind(this);
    this.onShowDeleteGalleryBanner = this.onShowDeleteGalleryBanner.bind(this);
    this.onDeleteGalleryImage = this.onDeleteGalleryImage.bind(this);
    this.onDeleteGalleryBanner = this.onDeleteGalleryBanner.bind(this);
    this.onImageAlertModalClose = this.onImageAlertModalClose.bind(this);
    this.onBannerAlertModalClose = this.onBannerAlertModalClose.bind(this);

    this.uploadedImages = [];
    this.uploadedBanners = [];

    this.googleAPI = null;
    this.googleMap = null;
    this.mapMarker = null;
    this.googleAddressAuto = null;
    this.googleAddressAutoByCode = null;
    this.onLocationSelected = this.onLocationSelected.bind(this);
    this.onLocationSelectedByCode = this.onLocationSelectedByCode.bind(this);

    this.onChangeBusinessAddress = this.onChangeBusinessAddress.bind(this);
    this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeOpeningHours = this.onChangeOpeningHours.bind(this);
    this.onChangeDisplayEmail = this.onChangeDisplayEmail.bind(this);
    this.onChangeDisplayPhone = this.onChangeDisplayPhone.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeWhatsappLink = this.onChangeWhatsappLink.bind(this);
    this.onChangeIGLink = this.onChangeIGLink.bind(this);
    this.onChangeWebsiteURL = this.onChangeWebsiteURL.bind(this);
    this.onChangeDeliveryDetail = this.onChangeDeliveryDetail.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);

    this.onValidationAlertClose = this.onValidationAlertClose.bind(this);
    this.onAddBusiness = this.onAddBusiness.bind(this);

    this.onStatusAlertClose = this.onStatusAlertClose.bind(this);
  }

  componentDidMount()
  {
    if(Cookies.get('jwtToken') != undefined)
    {
      let jwtToken = Cookies.get('jwtToken');
      console.log('token: ' + jwtToken);

      if(!isJwtExpired(jwtToken))
      {
        this.setState({jwtToken: jwtToken});

        var postData = {};
        let axiosConfig = {
          headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              'Authorization': jwtToken
          }
        };

        axios.post(global.config.lambdaGetBusinessByEmail, postData, axiosConfig)
        .then((res) => {

          var data = res['data'];
          if(data && data['result'])
          {
            let items = data['items'];
            if(items.length > 0)
            {
              console.log(items);
              let item = items[0];
              
              let images = JSON.parse(item.images);
              let uploadedImages = [];
              for(var i = 0; i < images.length; i++)
              {
                var image = images[i];
                uploadedImages.push({
                  original: global.config.thumbnailS3BucketPath + image.key,
                  thumbnail: global.config.thumbnailS3BucketPath + image.key
                });
              }
              this.uploadedImages = [...uploadedImages];

              let banners = JSON.parse(item.banners);
              let uploadedBanners = [];
              let selectedIndex = -1;
              for(var i = 0; i < banners.length; i++)
              {
                var banner = banners[i];
                
                if(banner.isSelected)
                  selectedIndex = i;

                uploadedBanners.push({
                  original: global.config.thumbnailS3BucketPath + banner.key,
                  thumbnail: global.config.thumbnailS3BucketPath + banner.key
                });
              }
              this.uploadedBanners = [...uploadedBanners];

              if(selectedIndex > -1)
              {
                setTimeout(
                  () => {
                    this.bannersGallery.current.slideToIndex(selectedIndex);
                  },
                  1000
                );
              }

              let mapPlace = JSON.parse(item.mapPlace);
              if(!(Object.keys(mapPlace).length === 0))
                this.setState({
                  mapCenter: {
                    lat: mapPlace.geometry.location.lat,
                    lng: mapPlace.geometry.location.lng
                  },
                  mapZoom: 16
                });

              this.setState({
                businessName: item.businessName,
                businessAddress: item.businessAddress,
                region: item.region,
                type: item.type,
                category: item.category,
                displayEmail: item.displayEmail,
                displayPhone: item.displayPhone,
                whatsappLink: item.whatsappLink,
                //igLink: ((item)=>{if(item.hasOwnProperty('igLink')) return item.igLink;else return '';})(item),
                igLink: this.getObjectValue(item, 'igLink', ''),
                websiteURL: item.websiteURL,
                description: item.description,
                openingHours: item.openHours,
                deliveryDetail: this.getObjectValue(item, 'deliveryDetail', ''),
                uploadedImages: uploadedImages,
                uploadedBanners: uploadedBanners,
                mapPlace: item.mapPlace,                  
                doesBusinessExist: true
              });

            }
          }

          setTimeout(
            () => {

              googleAPILoader.load().then((google) => {

                this.googleAPI = google;

                const mapOptions = {
                  center: {
                    lat: this.state.mapCenter.lat,
                    lng: this.state.mapCenter.lng
                  },
                  zoom: this.state.mapZoom
                };
                this.googleMap = new google.maps.Map(document.getElementById("map"), mapOptions);      

                if(this.state.doesBusinessExist)
                {
                  this.mapMarker = new this.googleAPI.maps.Marker({
                      position: this.state.mapCenter,
                      title: "My place",
                      animation: this.googleAPI.maps.Animation.DROP,
                  });
                  this.mapMarker.setMap(this.googleMap);
                }

                const options = {
                  componentRestrictions: { country: "uk" },
                  fields: ["address_components", "geometry", "icon", "name"],
                  strictBounds: false,
                  types: ["establishment"],
                };
                this.googleAddressAuto = new google.maps.places.Autocomplete(document.getElementById("addressAuto"), options);
                this.googleAddressAuto.addListener('place_changed', this.onLocationSelected);

                const optionsByCode = {
                  componentRestrictions: { country: "uk" },
                  fields: ["address_components", "geometry", "icon", "name"],
                  strictBounds: false,
                  types: ["(regions)"],
                };
                this.googleAddressAutoByCode = new google.maps.places.Autocomplete(document.getElementById("addressAutoByCode"), optionsByCode);
                this.googleAddressAutoByCode.addListener('place_changed', this.onLocationSelectedByCode);
              })
              .catch(e => {
                // do something
              });

            },
            1000
          );

        })
        .catch((err) => {
          console.log("AXIOS ERROR: " + JSON.stringify(err));
          console.log(err);
          
        });        
      }
      else
      {
        Cookies.remove('jwtToken');
        this.setState({jwtToken: ''});      
        this.props.navigate('/');
      }
    }
    else
    {
      Cookies.remove('jwtToken');
      this.setState({jwtToken: ''});      
      this.props.navigate('/');

      //window.location.href = './';
    }
  }

  getObjectValue(item, property, def)
  {
    if(item.hasOwnProperty(property)) 
      return item[property];
    else 
      return def;
  }

  onLocationSelected()
  {
    const place = this.googleAddressAuto.getPlace();
    this.setState({mapPlace: JSON.stringify(place)});
    this.setState({mapCenter: place.geometry.location});

    this.googleMap.setCenter(place.geometry.location);
    this.googleMap.setZoom(16);

    if(this.mapMarker != null)
      this.mapMarker.setMap(null);

    this.mapMarker = new this.googleAPI.maps.Marker({
        position: place.geometry.location,
        title: "My place",
        animation: this.googleAPI.maps.Animation.DROP,
    });
    this.mapMarker.setMap(this.googleMap);

    let address = this.formatAddress(place.address_components);
    this.setState({businessAddress: address});
    this.setState({region: this.findRegion(place.address_components)})

    //console.log(place);
  }

  onLocationSelectedByCode()
  {
    const place = this.googleAddressAutoByCode.getPlace();
    this.setState({mapPlace: JSON.stringify(place)});
    this.setState({mapCenter: place.geometry.location});

    this.googleMap.setCenter(place.geometry.location);
    this.googleMap.setZoom(16);

    if(this.mapMarker != null)
      this.mapMarker.setMap(null);

    this.mapMarker = new this.googleAPI.maps.Marker({
        position: place.geometry.location,
        title: "My place",
        animation: this.googleAPI.maps.Animation.DROP,
    });
    this.mapMarker.setMap(this.googleMap);

    let address = this.formatAddress(place.address_components);
    this.setState({businessAddress: address});
    this.setState({region: this.findRegion(place.address_components)})

    //console.log('region: ' + this.findRegion(place.address_components));
  }

  formatAddress(parts)
  {
    let address = '';
    for(let i = 0; i < parts.length; i++)
    {
      address += parts[i].long_name;
      if(i < parts.length - 1)
        address += ', ';
    }

    return address;    
  }

  findRegion(parts)
  {
    let region = '';

    for(let i = 0; i < parts.length; i++)
    {
      if(parts[i].types[0] == 'administrative_area_level_2')
      {
        region = parts[i].long_name;
        break;
      }
    }

    return region;
  }

  onShowDeleteGalleryImage(e)
  {
    e.preventDefault();    
    this.setState({isImageDeleteAlertOpen: true});
  }

  onShowDeleteGalleryBanner(e)
  {
    e.preventDefault(); 
    this.setState({isBannerDeleteAlertOpen: true});
  }

  onDeleteGalleryImage(e)
  {
    e.preventDefault();

    let index = this.imagesGallery.current.getCurrentIndex();
    let dummy = [...this.state.uploadedImages];
    dummy.splice(index, 1);

    this.uploadedImages = [...dummy];
    this.setState({isImageDeleteAlertOpen: false, uploadedImages: dummy}); 
  }

  onDeleteGalleryBanner(e)
  {
    e.preventDefault();

    let index = this.bannersGallery.current.getCurrentIndex();
    let dummy = [...this.state.uploadedBanners];
    dummy.splice(index, 1);

    this.uploadedBanners = [...dummy];
    this.setState({isBannerDeleteAlertOpen: false, uploadedBanners: dummy});
  }

  onImageAlertModalClose()
  {
    this.setState({isImageDeleteAlertOpen: false});
  }

  onBannerAlertModalClose()
  {
    this.setState({isBannerDeleteAlertOpen: false});
  }  

  onDropBanner(pictureFiles, pictureDataURLs) {
    
    this.setState({
      banners: pictureFiles
    });

  }

  onDropImage(pictureFiles, pictureDataURLs) {
    
    this.setState({
      pictures: pictureFiles
    });

  }

  async onUploadBanner()
  {
    this.setState({isBannerUploading: true});
    this.setState({bannerUploadStatus: 'Uploading...'});

    this.uploadedBanners = [...this.state.uploadedBanners];

    var files = this.state.banners;
    for(var i = 0; i < files.length; i++)
    {
      var file = files[i];

      var data = await this.uploadToS3(file, 'banners/');
      console.log(JSON.stringify(data));

      var imageURL = global.config.thumbnailS3BucketPath + data['fileName'];
      this.uploadedBanners.push({original: imageURL, thumbnail: imageURL, isLoaded: false});
    }
    
    this.setState({bannerUploadStatus: 'Creating thumbnail...'});
    this.bannerUploader.current.reset();
    this.setState({isBannerUploading: false, isBannerCreatingThumbnail: true});

    this.checkBannerReady();
  }

  async onUploadFiles()
  {
    this.setState({isImageUploading: true});
    this.setState({imageUploadStatus: 'Uploading...'});

    this.uploadedImages = [...this.state.uploadedImages];

    var files = this.state.pictures;
    for(var i = 0; i < files.length; i++)
    {
      var file = files[i];

      try{
        var data = await this.uploadToS3(file, 'images/');
        console.log(JSON.stringify(data));
      }
      catch(e)
      {
        console.log('failed to upload file');
        console.log(e);

        i--;
        continue;
      }

      var imageURL = global.config.thumbnailS3BucketPath + data['fileName'];
      this.uploadedImages.push({original: imageURL, thumbnail: imageURL, isLoaded: false});
    }
    
    this.setState({imageUploadStatus: 'Creating thumbnail...'});
    this.imageUploader.current.reset();
    this.setState({isImageUploading: false, isImageCreatingThumbnail: true});

    this.checkImageReady();
  }

  uploadToS3(file, folder)
  {
    
    return new Promise((resolve, reject)=>{
      
      if(file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/webp')
      {
        var fileType = 'png';
        if(file.type == 'image/jpeg')
          fileType = 'jpg';
        else if(file.type == 'image/webp')
          fileType = 'webp';

        var fileName = md5(Date.now() + '-' + file.name) + '.' + fileType;
        var fullPath = folder + fileName;

        // Use S3 ManagedUpload class as it supports multipart uploads
        
        var upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: 'feedeasy',
            Key: fullPath,
            Body: file
          }
        });
      
        var promise = upload.promise().then(
          (data) => {
            //alert("Successfully uploaded photo.");
            data['fileName'] = fileName;
            resolve(data);            

          },
          (err) => {
            
            console.log('upload error');
            console.log(JSON.stringify(err));
            reject(err);

          }
        );
                
      }
      else
      {
        reject({reason: 'file type not supported'});
      }

    });
    
  }

  onStatusAlertClose()
  {
    if(this.state.statusIndex == 0)
    {
      //window.location.reload();
      this.setState({doesBusinessExist: true});
    }
    else if(this.state.statusIndex == 2)
    {
      Cookies.remove('jwtToken');
      this.setState({jwtToken: ''});      
      this.props.navigate('/');

      //window.location.href = './';
    }

    this.setState({isStatusAlertOpen: false});    
  }

  onValidationAlertClose()
  {
    let errorIndex = this.state.errorIndex;

    let element =  '';
    if(errorIndex == 0)
      element = 'businessNameInput';
    else if(errorIndex == 1)
      element = 'businessDescriptionInput';
    else if(errorIndex == 2)
      element = 'openingHoursInput';
    else if(errorIndex == 3)
      element = 'displayEmailInput';
    else if(errorIndex == 4)
      element = 'businessAddressInput';
    else if(errorIndex == 5)
      element = 'addressAutoByCode';
    
    this.setState({
      isValidationAlertOpen: false,
      errorIndex: -1
    });
    
    if(element != '')
    {
      this.timerID = setInterval(
        () => {

          document.getElementById(element).scrollIntoView({behavior: 'smooth', block: 'center'});
          clearInterval(this.timerID);

        },
        800
      );
    }
  }

  onAddBusiness(e)
  {
    e.preventDefault();

    if(this.state.pictures.length > 0 || this.state.banners.length > 0)
    {
      this.setState({
        isValidationAlertOpen: true,
        errorIndex: 6
      });  
    }
    else if(this.state.businessName == '')
    {
      this.setState({
        isValidationAlertOpen: true,
        errorIndex: 0
      });      
    }
    else if(this.state.description == '')
    {
      this.setState({
        isValidationAlertOpen: true,
        errorIndex: 1
      });            
    }
    else if(this.state.openingHours == '')
    {
      this.setState({
        isValidationAlertOpen: true,
        errorIndex: 2
      });            
    }
    else if(this.state.region == '' && this.state.type == 1)
    {
      this.setState({
        isValidationAlertOpen: true,
        errorIndex: 5
      });            
    }    
    else if(this.state.businessAddress == '' && this.state.type == 1)
    {
      this.setState({
        isValidationAlertOpen: true,
        errorIndex: 4
      });            
    }
    else
    {
      let images = [];
      for(var i = 0; i < this.uploadedImages.length; i++)
      {
        let chunks = this.uploadedImages[i].original.split('/');
        images.push({key: chunks[chunks.length - 1]});
      }

      let banners = [];
      let selectedBannerIndex = 0;
      if(this.bannersGallery.current)
        selectedBannerIndex = this.bannersGallery.current.getCurrentIndex();

      for(var i = 0; i < this.uploadedBanners.length; i++)
      {
        let flag = false;
        if(selectedBannerIndex == i)
          flag = true;

        let chunks = this.uploadedBanners[i].original.split('/');
        banners.push({key: chunks[chunks.length - 1], isSelected: flag});
      }

      var postData = {
        region: this.state.region,
        type: this.state.type,
        category: this.state.category,
        businessName: this.state.businessName,
        businessAddress: this.state.businessAddress,
        displayEmail: this.state.displayEmail,
        displayPhone: this.state.displayPhone,
        whatsappLink: this.state.whatsappLink,
        igLink: this.state.igLink,
        websiteURL: this.state.websiteURL,
        description: this.state.description,
        openHours: this.state.openingHours,
        deliveryDetail: this.state.deliveryDetail,
        mapPlace: this.state.mapPlace,
        images: JSON.stringify(images),
        banners: JSON.stringify(banners)
      };
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': this.state.jwtToken
        }
      };

      console.log(postData);
      this.setState({isAdding: true});

      axios.post(global.config.lambdaAddBusiness, postData, axiosConfig)
      .then((res) => {

        var data = res['data'];
        if(data && data['result'])
        {
          if(!this.state.doesBusinessExist)
            this.setState({isStatusAlertOpen: true, statusIndex: 0});
          else
            this.setState({isStatusAlertOpen: true, statusIndex: 1});

          this.setState({isAdding: false});
        }

      })
      .catch((err) => {
        console.log("AXIOS ERROR: " + JSON.stringify(err));
        
        this.setState({isStatusAlertOpen: true, statusIndex: 2});
        this.setState({isAdding: false});
      });


    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////

  onChangeType(e)
  {
    console.log(e.target.value);
    this.setState({type: e.target.value});
  }

  onChangeCategory(e)
  {
    console.log(e.target.value);
    this.setState({category: e.target.value});
  }

  onChangeBusinessName(e)
  {
    e.preventDefault();
    this.setState({businessName: e.target.value});
  }

  onChangeDescription(e)
  {
    e.preventDefault();
    this.setState({description: e.target.value});
  }

  onChangeOpeningHours(e)
  {
    e.preventDefault();
    this.setState({openingHours: e.target.value});
  }

  onChangeDisplayEmail(e)
  {
    e.preventDefault();
    this.setState({displayEmail: e.target.value});
  }

  onChangeDisplayPhone(e)
  {
    e.preventDefault();
    this.setState({displayPhone: e.target.value});
  }

  onChangeWhatsappLink(e)
  {
    e.preventDefault();
    this.setState({whatsappLink: e.target.value});
  }

  onChangeIGLink(e)
  {
    e.preventDefault();
    this.setState({igLink: e.target.value});
  }

  onChangeWebsiteURL(e)
  {
    e.preventDefault();
    this.setState({websiteURL: e.target.value});
  }

  onChangeBusinessAddress(event)
  {
    this.setState({businessAddress: event.target.value});
  }

  onChangeDeliveryDetail(e)
  {
    this.setState({deliveryDetail: e.target.value}); 
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  async checkImageReady()
  {
    var images = this.uploadedImages;
    for(let i = 0; i < images.length; i++)
    {
      let image = images[i];
      if(!image.isLoaded)
      {
        var data = await this.loadImageFromURL(image.thumbnail + '?' + Date.now());
        if(data.result)
          image.isLoaded = true;
      }
    }

    var allLoaded = true;
    for(var i = 0; i < images.length; i++)
    {
      var image = images[i];
      if(!image.isLoaded)
      {
        allLoaded = false;
        break;
      }
    }

    if(!allLoaded)
    {
      console.log('call again after 4000');      
      setTimeout(()=>{

        this.checkImageReady();

      }, 4000);      
    }
    else
    {
      //console.log('yes all loaded');
      this.setState({uploadedImages: images, isImageCreatingThumbnail: false});
    }
  }

  async checkBannerReady()
  {
    var images = this.uploadedBanners;
    for(let i = 0; i < images.length; i++)
    {
      let image = images[i];
      if(!image.isLoaded)
      {
        var data = await this.loadImageFromURL(image.thumbnail + '?' + Date.now());
        if(data.result)
          image.isLoaded = true;
      }
    }

    var allLoaded = true;
    for(var i = 0; i < images.length; i++)
    {
      var image = images[i];
      if(!image.isLoaded)
      {
        allLoaded = false;
        break;
      }
    }

    if(!allLoaded)
    {
      console.log('call again after 4000');      
      setTimeout(()=>{

        this.checkBannerReady();

      }, 4000);      
    }
    else
    {
      //console.log('yes all loaded');
      this.setState({uploadedBanners: images, isBannerCreatingThumbnail: false});
    }
  }

  loadImageFromURL(url)
  {
    return new Promise((resolve, reject)=>{

      axios.get(url)
      .then(function (response) {
        // handle success
        //console.log(response);
        resolve({result: true});
      })
      .catch(function (error) {
        // handle error
        //console.log(error);
        resolve({result: false});
      });

    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////


  render() {
    return (

      <React.Fragment>
        
        <div className="container">
        
          <div className="row" style={{paddingTop: '4rem', paddingBottom: '4rem'}}>
            <div className="col navMenu">
              <Center><Text fontSize='2xl'>~Add to Directory~</Text></Center>
            </div>
          </div>
          
          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Business Name *</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Input id="businessNameInput" width='100%' placeholder='Please enter your business name' value={this.state.businessName} onChange={this.onChangeBusinessName} isDisabled={this.state.doesBusinessExist} />
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Type of Service *</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Select onChange={this.onChangeType} value={this.state.type}>                
                <option value='1'>Dine-in</option>
                <option value='2'>Takeaway</option>
                <option value='3'>Ingredients</option>
                <option value='4'>Other</option>
              </Select>
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Category *</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Select onChange={this.onChangeCategory} value={this.state.category}>                
                <option value='0'>Any Style</option>
                <option value='1'>Hong Kong Style</option>
                <option value='2'>Cantonese Style</option>
                <option value='3'>Chinese Restaurant</option>
                <option value='4'>Japanese</option>
                <option value='5'>Thai</option>
                <option value='6'>Vietnamese</option>
                <option value='7'>Korean</option>                
                <option value='8'>Fusion</option>
                <option value='9'>Dessert / Tea</option>
              </Select>
            </div>
          </div>
        
          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Service Introduction *</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Textarea id="businessDescriptionInput" width='100%' rows='10' placeholder='Please enter your service introduction' value={this.state.description} onChange={this.onChangeDescription} />
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Servicing Time *</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Textarea id="openingHoursInput" width='100%' rows='6' placeholder='Please enter your servicing time' value={this.state.openingHours} onChange={this.onChangeOpeningHours} />
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Delivery Arrangement</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Textarea id="openingHoursInput" width='100%' rows='6' placeholder='Please enter your delivery arrangement details if any' value={this.state.deliveryDetail} onChange={this.onChangeDeliveryDetail} />
            </div>
          </div>
          
          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              {this.state.type == 1 &&
              <h2>Business Address *</h2>
              }
              {this.state.type > 1 &&
              <h2>Business Address</h2>
              }              
            </div>
          </div>
          <div className="row" style={{paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <Input id="addressAuto" width='100%' placeholder='Please enter your business name / label recognized by Google Map' />
            </div>
          </div>
          <div className="row" style={{paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <Center width={'100%'}><Text color={'#999999'}>or searching by postal code</Text></Center>
            </div>
          </div>
          <div className="row" style={{paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <Input id="addressAutoByCode" width='100%' placeholder='Pleas enter your Postal Code' />            
            </div>
          </div>
          
          <div className="row" style={{paddingBottom: '0.6rem'}}>            
            <div className="form-group col-12">
              <div id="map" className="row" style={{marginLeft: '0', height: '60vh', width: '100%'}}></div>            
            </div>
          </div>

          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Textarea id="businessAddressInput" width='100%' rows='4' placeholder='Please enter your business address here if Google Map cannot locate yours.' value={this.state.businessAddress} onChange={this.onChangeBusinessAddress} />
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Display Phone Number</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color='gray.300'
                  fontSize='1.2em'
                  children='+44'
                />
                <Input placeholder='Please enter a phone number' value={this.state.displayPhone} onChange={this.onChangeDisplayPhone} />
              </InputGroup>
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Display Email</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color='gray.300'
                  fontSize='1.2em'
                  children='@'
                />
                <Input id="displayEmailInput" placeholder='Please enter an email' value={this.state.displayEmail} onChange={this.onChangeDisplayEmail} />
              </InputGroup>
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Whatsapp Link</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Input placeholder='Please enter a Whatsapp link' value={this.state.whatsappLink} onChange={this.onChangeWhatsappLink} />              
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Instagram</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Input placeholder='Please enter a link to your IG account if any' value={this.state.igLink} onChange={this.onChangeIGLink} />              
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Business Website</h2>
            </div>
          </div>  
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Input placeholder='Please enter your business website address here' value={this.state.websiteURL} onChange={this.onChangeWebsiteURL} />              
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Banner Image</h2>
            </div>
          </div>
          {this.state.uploadedBanners.length > 0 &&
          <div className="row" style={{paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <ImageGallery items={this.state.uploadedBanners}
                showPlayButton={false}
                showIndex={true}
                showFullscreenButton={false}
                ref={this.bannersGallery}
              />
            </div>
            <div className="form-group col-12">
                <Button
                  width='100%'
                  colorScheme={'red'}
                  isLoading={false}
                  isDisabled={this.state.isAdding}
                  onClick={this.onShowDeleteGalleryBanner}
                >
                  Delete this one
                </Button>
            </div>
          </div>
          }

          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="col bg-light">                      
              <div className="form-group col-12">
                <ReactImageUploadComponent
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={this.onDropBanner}
                    imgExtension={['.jpeg', '.jpg', '.png']}
                    maxFileSize={10485760}
                    withPreview={true}
                    singleImage={true}
                    ref={this.bannerUploader}
                    buttonText={'Choose your image(s)'}
                    isDisabled={this.state.isBannerUploading}
                />
              </div>
              {(this.state.banners.length > 0 || this.state.isBannerUploading || this.state.isBannerCreatingThumbnail) &&
              <div className="form-group col-12">
                <Button
                  width='100%'
                  isLoading={this.state.isBannerUploading || this.state.isBannerCreatingThumbnail}
                  loadingText={this.state.bannerUploadStatus}
                  colorScheme={'green'}
                  onClick={this.onUploadBanner}
                >
                  Upload
                </Button>                
              </div>
              }
            </div>
          </div>

          <div className="row" style={{paddingLeft: '0.4rem', paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <h2>Gallery Image</h2>
            </div>
          </div>
          {this.state.uploadedImages.length > 0 &&
          <div className="row" style={{paddingBottom: '0.6rem'}}>
            <div className="form-group col-12">
              <ImageGallery items={this.state.uploadedImages}
                showPlayButton={false}
                showIndex={true}
                showFullscreenButton={false}
                ref={this.imagesGallery}
              />
            </div>
            <div className="form-group col-12">
                <Button
                  width='100%'
                  colorScheme={'red'}
                  isLoading={false}
                  isDisabled={this.state.isAdding}
                  onClick={this.onShowDeleteGalleryImage}
                >
                  Delete this one
                </Button>
            </div>
          </div>
          }          
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="col bg-light">                      
              <div className="form-group col-12">
                <ReactImageUploadComponent
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={this.onDropImage}
                    imgExtension={['.jpeg', '.jpg', '.png', '.webp']}
                    maxFileSize={10485760}
                    withPreview={true}
                    singleImage={false}
                    ref={this.imageUploader}
                    buttonText={'Choose your image(s)'}
                    isDisabled={this.state.isImageUploading}
                />
              </div>
              {(this.state.pictures.length > 0 || this.state.isImageUploading || this.state.isImageCreatingThumbnail) &&
              <div className="form-group col-12">
                <Button
                  width='100%'
                  isLoading={this.state.isImageUploading || this.state.isImageCreatingThumbnail}
                  loadingText={this.state.imageUploadStatus}
                  colorScheme={'green'}
                  onClick={this.onUploadFiles}
                >
                  Upload
                </Button>
              </div>
              }
            </div>
          </div>

          <div className="row" style={{paddingBottom: '2rem'}}>
              <div className="form-group col-12">
                {this.state.doesBusinessExist &&
                <Button
                  width='100%'
                  isLoading={this.state.isAdding}
                  loadingText={'Updating⋯⋯'}
                  colorScheme={'blue'}
                  isDisabled={this.state.isImageUploading || this.state.isBannerUploading}
                  onClick={this.onAddBusiness}
                >
                  Confirm Modification
                </Button>
                }

                {!this.state.doesBusinessExist &&
                <Button
                  width='100%'
                  isLoading={this.state.isAdding}
                  loadingText={'Adding⋯⋯'}
                  colorScheme={'blue'}
                  isDisabled={this.state.isImageUploading || this.state.isBannerUploading}
                  onClick={this.onAddBusiness}
                >
                  Confirm to Add
                </Button>
                }
              </div>
          </div>
          
          <AlertDialog
            isOpen={this.state.isBannerDeleteAlertOpen}
            onClose={this.onBannerAlertModalClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Delete Banner
                </AlertDialogHeader>

                <AlertDialogBody>
                  Confirm Deletion？
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button 
                  //ref={cancelRef} 
                  onClick={this.onBannerAlertModalClose}
                  >
                    Cancel
                  </Button>
                  <Button colorScheme='red' 
                  onClick={this.onDeleteGalleryBanner} 
                  ml={3}>
                    Confirm
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <AlertDialog
            isOpen={this.state.isImageDeleteAlertOpen}
            onClose={this.onImageAlertModalClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Delete Image
                </AlertDialogHeader>

                <AlertDialogBody>
                  Confirm Deletion？
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button 
                  //ref={cancelRef} 
                  onClick={this.onImageAlertModalClose}
                  >
                    Cancel
                  </Button>
                  <Button colorScheme='red' 
                  onClick={this.onDeleteGalleryImage} 
                  ml={3}>
                    Confirm
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <AlertDialog
            isOpen={this.state.isValidationAlertOpen}
            onClose={this.onValidationAlertClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Attention
                </AlertDialogHeader>

                <AlertDialogBody>
                  {this.state.errorMessageList[this.state.errorIndex]}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button colorScheme='blue' 
                  onClick={this.onValidationAlertClose} 
                  ml={3}>
                    Confirm
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <AlertDialog
            isOpen={this.state.isStatusAlertOpen}
            onClose={this.onStatusAlertClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Status
                </AlertDialogHeader>

                <AlertDialogBody>
                  {this.state.statusMessageList[this.state.statusIndex]}
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button colorScheme='blue' 
                  onClick={this.onStatusAlertClose} 
                  ml={3}>
                    Confirm
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        
        </div>

      </React.Fragment>

    )
  }

}

export default injectTodos(AddEntity);
