import logo from './logo.svg';
import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Select,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack, HStack, VStack, StackDivider,
  Box,
  Icon,
  Center,
  Spinner
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FaPeriscope, FaPhoneAlt, FaWhatsapp, FaShareAlt, FaCalendarAlt } from 'react-icons/fa';
import Carousel from './Carousel';
import axios from 'axios';
import {isIOS, isMacOs} from 'react-device-detect';
import { useOutletContext } from "react-router-dom";

function injectTodos(Component) {
  const InjectedTodos = function (props) {
    
    const outletContext = useOutletContext();

    return <Component {...props} parentStates={outletContext} />;
  };
  return InjectedTodos;
}

class RestaurantList extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      region: '',
      regions: [],
      category: 0,
      categories: [
        'Any Style',
        'Hong Kong Style',
        'Cantonese Style',
        'Chinese Restaurant',
        'Japanese',
        'Thai',
        'Vietnamese',
        'Korean',
        'Fusion',
        'Dessert / Tea'
      ],
      items:{
        type1: [],
        type2: [],
        type3: [],
        type4: []
      },
      recipes: [],
      selectedItem: {},
      isItemModalOpen: false,
      isLoadingModalOpen: true,
      isRegionSpecified: false
    };

    this.onChangeRegion = this.onChangeRegion.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.parseItems = this.parseItems.bind(this);
    this.showItemModal = this.showItemModal.bind(this);
    this.onCloseItemModal = this.onCloseItemModal.bind(this);
    this.renderBusinessEntity = this.renderBusinessEntity.bind(this);

    this.mapOpenURL = 'https://maps.google.com/?q=';
    if(isMacOs || isIOS)
      this.mapOpenURL = 'https://maps.google.com/maps?q=';
  }

  componentDidMount()
  {
    console.log(this.props.parentStates.region);

    axios.get(global.config.lambdaGetRecipes)
    .then((res) => {

       var data = res['data'];
      if(data && data['result'])
      {
        let items = data['items'];
        this.setState({
          recipes: items
        });
      }

    });

    axios.get(global.config.lambdaGetRegions)
    .then((res) => {

      var data = res['data'];
      if(data && data['result'])
      {
        let items = data['items'];

        let regions = [];
        for(var i = 0; i < items.length; i++)
        {          
          regions.push(items[i].zone);
        }
        this.setState({regions: regions});

        let defaultRegion = 'Greater Manchester';
        if(this.props.parentStates.region != '')
        {
          for(let i = 0; i < regions.length; i++)
          {
            if(regions[i] == this.props.parentStates.region)
            {              
              defaultRegion = this.props.parentStates.region;
              this.setState({isRegionSpecified: true});

              break;    
            }
          }          
        }
        this.setState({region: defaultRegion});

        // load entities from a region
        var postData = {region: defaultRegion};
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        };
        axios.post(global.config.lambdaGetBusinessList, postData, axiosConfig)
        .then((res) => {

          var data = res['data'];
          if(data && data['result'])
          {
            let items = data['items'];
            console.log(items);
            this.parseItems(items);
          }

          this.setState({isLoadingModalOpen: false});

        })
        .catch((err) => {

          this.setState({isLoadingModalOpen: false});

        });

      }

     })
    .catch((err) => {
      console.log("AXIOS ERROR: " + JSON.stringify(err));
      
    });
  }

  showItemModal(item)
  {
    this.setState({isItemModalOpen: true, selectedItem: item});

  }

  onCloseItemModal()
  {
    this.setState({isItemModalOpen: false});
  }

  onChangeRegion(e)
  {
    this.setState({
      region: e.target.value,
      category: 0
    });

    var postData = {region: e.target.value};
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    axios.post(global.config.lambdaGetBusinessList, postData, axiosConfig)
    .then((res) => {

      var data = res['data'];
      if(data && data['result'])
      {
        let items = data['items'];
        console.log(items);
        this.parseItems(items);
      }

    })
    .catch((err) => {

    });

  }

  onChangeCategory(e)
  {
    console.log(e.target.value);
    this.setState({category: e.target.value});
  }

  parseItems(items)
  {
    let type1 = [];
    let type2 = [];
    let type3 = [];
    let type4 = [];

    for(var i = 0; i < items.length; i++)
    {
      let item = items[i];

      let location = {lat: 0, lng: 0};
      let selectedBanner = {};
      let banners = JSON.parse(item.banners);
      for(var j = 0; j < banners.length; j++)
      {
        let banner = banners[j];
        if(banner.isSelected)
        {
          selectedBanner = banner;
          break;
        }
      }
      item.banner = selectedBanner;

      let place = JSON.parse(item.mapPlace);
      if(!(Object.keys(place).length === 0))
      {
        location.lat = place.geometry.location.lat;
        location.lng = place.geometry.location.lng;        
      }      
      item.location = location;

      if(item.type == 1)
        type1.push(item);
      else if(item.type == 2)
        type2.push(item);
      else if(item.type == 3)
        type3.push(item);
      else if(item.type == 4)
        type4.push(item);      
    }

    this.setState({
      items:{
        type1: type1,
        type2: type2,
        type3: type3,
        type4: type4
      }
    });
  }

renderRecipt(recipe)
{
  let recipeObject = JSON.parse(recipe.content);

  let firstTextParagraph = null;
  for(let i = 0; i < recipeObject.paragraphs.length; i++)
  {
    let paragraph = recipeObject.paragraphs[i];
    if(paragraph.type == 1)
    {
      firstTextParagraph = paragraph;
      break;
    }
  }

  return (

    <div key={recipe.id}>
      <div className="row bg-light"><br /><br /></div>                  
      <div className="row bg-light">
        <div className="col-md-6 order-md-1 order-2" data-aos="fade-up">
          <a href={global.config.shareRecipeURL + recipe.id} target="_blank">
            {recipeObject.hasOwnProperty('thumbnail') &&
            <img className="img-fluid " src={global.config.thumbnailS3BucketPath + recipeObject.thumbnail} />
            }
            {!recipeObject.hasOwnProperty('thumbnail') &&
            <img className="img-fluid " src={global.config.recipeS3BucketPath + 'default.png'} />
            }
          </a>
        </div>
        <div className="col-md-6 order-md-12 order-1">
          <a href={global.config.shareRecipeURL + recipe.id} target="_blank" style={{textDecoration: 'none', color: 'black'}}>
          <h3 style={{fontSize: '1.75rem', marginBottom: '0.4rem'}}>{recipeObject.title}</h3>
          {firstTextParagraph &&
          <Text noOfLines={[1, 2, 6]}>{firstTextParagraph.content}</Text>
          }
          </a>
        </div>
      </div>
    </div>

  )
}

renderBusinessEntity(item)
{
  let selectedCategory = this.state.category;

  return(
    
    <div key={item.businessName}> 
      {(selectedCategory == 0 || item.category == this.state.category) &&
      <React.Fragment>
      <div className="row bg-light"><br /><br /></div>                  
      <div className="row bg-light">
        <div className="col-md-6 order-md-1 order-2" data-aos="fade-up">
          <a href="#" onClick={(e)=>{e.preventDefault();this.showItemModal(item);}}><img className="img-fluid " src={global.config.thumbnailS3BucketPath + item.banner.key} /></a>
        </div>
        <div className="col-md-6 order-md-12 order-1">
          <a href="#" onClick={(e)=>{e.preventDefault();this.showItemModal(item);}} style={{textDecoration: 'none', color: 'black'}}>
          <h3 style={{fontSize: '1.75rem', marginBottom: '0.4rem'}}>{item.businessName}</h3>
          <Text noOfLines={[1, 2, 6]}>{item.description}</Text>
          </a>
        </div>
      </div>      
      </React.Fragment>
      }
    </div>
    
  )
}

render() {
    return (

      <React.Fragment>
        
        <div style={{marginTop: '100px'}}>
        </div>

        {!this.state.isRegionSpecified &&
        <div>
          <Carousel />
        </div>
        }

        <div className="container">      

          <div className="row">
            <div className="col navMenu">
          <Tabs>
            <TabList>
              {this.state.items.type1.length > 0 && <Tab>Dine-in</Tab>}
              {this.state.items.type2.length > 0 && <Tab>Takeaway</Tab>}
              {this.state.items.type3.length > 0 && <Tab>Ingredients</Tab>}
              {this.state.items.type4.length > 0 && <Tab>Other</Tab>}
              <Tab>Recipe</Tab>
            </TabList>

            <TabPanels>

              {this.state.items.type1.length > 0 && 
              <TabPanel>

                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeRegion} value={this.state.region} isDisabled={this.state.regions == 0}>
                  {
                    this.state.regions.map( (region) => 
                      <option key={region}>{region}</option> )
                  }                  
                  </Select>                  

                </div>

                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeCategory} value={this.state.category} isDisabled={this.state.categories.length == 0}>
                  {
                    this.state.categories.map( (category) => 
                      <option value={this.state.categories.indexOf(category)} key={category}>{category}</option> )
                  }
                  </Select>

                </div>

                {
                  this.state.items.type1.map( (item) =>

                  this.renderBusinessEntity(item)

                  )
                }
                <div className="row bg-light"><br /></div>

              </TabPanel>
              }

              {this.state.items.type2.length > 0 && 
              <TabPanel>
                
                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeRegion} value={this.state.region} isDisabled={this.state.regions == 0}>
                  {
                    this.state.regions.map( (region) => 
                      <option key={region}>{region}</option> )
                  }                  
                  </Select>

                </div>

                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeCategory} value={this.state.category} isDisabled={this.state.categories.length == 0}>
                  {
                    this.state.categories.map( (category) => 
                      <option value={this.state.categories.indexOf(category)} key={category}>{category}</option> )
                  }                  
                  </Select>

                </div>

                {
                  this.state.items.type2.map( (item) =>

                    this.renderBusinessEntity(item)

                  )
                }
                <div className="row bg-light"><br /></div>                

              </TabPanel>
              }

              {this.state.items.type3.length > 0 && 
              <TabPanel>

                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeRegion} value={this.state.region} isDisabled={this.state.regions == 0}>
                  {
                    this.state.regions.map( (region) => 
                      <option key={region}>{region}</option> )
                  }                  
                  </Select>

                </div>

                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeCategory} value={this.state.category} isDisabled={this.state.categories.length == 0}>
                  {
                    this.state.categories.map( (category) => 
                      <option value={this.state.categories.indexOf(category)} key={category}>{category}</option> )
                  }                  
                  </Select>

                </div>

                {
                  this.state.items.type3.map( (item) =>

                    this.renderBusinessEntity(item)

                  )
                }
                <div className="row bg-light"><br /></div>

              </TabPanel>
              }

              {this.state.items.type4.length > 0 && 
              <TabPanel>

                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeRegion} value={this.state.region} isDisabled={this.state.regions.length == 0}>
                  {
                    this.state.regions.map( (region) => 
                      <option key={region}>{region}</option> )
                  }                  
                  </Select>

                </div>

                <div className="row" style={{marginBottom: '1rem'}}>

                  <Select onChange={this.onChangeCategory} value={this.state.category} isDisabled={this.state.categories.length == 0}>
                  {
                    this.state.categories.map( (category) => 
                      <option value={this.state.categories.indexOf(category)} key={category}>{category}</option> )
                  }                  
                  </Select>

                </div>

                {
                  this.state.items.type4.map( (item) =>

                    this.renderBusinessEntity(item)

                  )
                }
                <div className="row bg-light"><br /></div>

              </TabPanel>
              }

              <TabPanel>
                {
                  this.state.recipes.map( (recipe) =>

                    this.renderRecipt(recipe)

                  )
                }    
              </TabPanel>            

            </TabPanels>

          </Tabs>
            </div>
          </div>

        </div>

        <Modal isCentered isOpen={this.state.isLoadingModalOpen}> size={'xs'}
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
          />
          <ModalContent>
            <ModalHeader></ModalHeader>            
            <ModalBody>
              <VStack spacing='24px'>
              <Center><Spinner
                  thickness='3px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='yellow.300'
                  size='xl'
                />
              </Center>
              <Center><Text color='gray.500'>Loading...</Text></Center>
              </VStack>
            </ModalBody>
            <ModalFooter>              
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          onClose={this.onCloseItemModal}
          //finalFocusRef={btnRef}
          isOpen={this.state.isItemModalOpen}
          scrollBehavior={'inside'}
          size={'xl'}
        >
          <ModalOverlay />
          {this.state.isItemModalOpen &&
          <ModalContent>
            <ModalHeader>{this.state.selectedItem.businessName}</ModalHeader>            
            <ModalBody>
              <VStack
                divider={<StackDivider borderColor='gray.200' />}
                spacing={4}
                align='stretch'
              >
                <VStack>
                <Box>                  
                  <a href={global.config.shareURL + encodeURI(this.state.selectedItem.businessName)} style={{textDecoration: 'none', color: 'black'}} target="_blank">
                    <img src={global.config.bannerS3BucketPath + this.state.selectedItem.banner.key}></img>                        
                  </a>
                </Box>

                <Box w='100%'>
                  <Button
                    width='100%'
                    colorScheme={'yellow'}
                    onClick={(e)=>{

                      e.preventDefault();
                      window.open(global.config.shareURL + encodeURI(this.state.selectedItem.businessName), '_blank');

                    }}
                  >
                    More details
                  </Button>
                </Box>
                </VStack>

                {this.state.selectedItem.openHours != '' &&
                <Box>
                  <HStack spacing='24px'>
                    <Center w='2.4em'><Icon as={FaCalendarAlt} boxSize={'1.6em'} /></Center>
                    <Box w='80%'>
                      
                      <Text dangerouslySetInnerHTML={{__html: 
                        (()=>{if (this.state.selectedItem.openHours) return this.state.selectedItem.openHours.replace(/\n/g, "<br />");else return '';})()
                      }}> 
                      </Text>

                    </Box>
                  </HStack>
                </Box>
                }

                {this.state.selectedItem.displayPhone != '' &&
                <Box>
                  <HStack spacing='24px'>
                    <Center w='2.4em'><Icon as={FaPhoneAlt} boxSize={'1.6em'} /></Center>
                    <Box w='80%'>
                      <Text>

                      <a href={'tel:' + this.state.selectedItem.displayPhone.replace(/ /g,'')} style={{textDecoration: 'none', color: 'black'}} target="_blank">
                        {this.state.selectedItem.displayPhone} <ExternalLinkIcon />
                      </a>

                      </Text>
                    </Box>
                  </HStack>
                </Box>
                }

                {this.state.selectedItem.region != 'nil' &&
                <Box>
                  <HStack spacing='24px'>
                    <Center w='2.4em'><Icon as={FaPeriscope} boxSize={'1.6em'} /></Center>
                    <Box w='80%'>                      
                      <Text>

                      <a href={this.mapOpenURL + this.state.selectedItem.location.lat + ',' + this.state.selectedItem.location.lng} style={{textDecoration: 'none', color: 'black'}} target="_blank">
                        {this.state.selectedItem.businessAddress} <ExternalLinkIcon />
                      </a>

                      </Text>
                    </Box>
                  </HStack>
                </Box>
                }
                
                {this.state.selectedItem.whatsappLink != '' &&
                <Box>
                  <HStack spacing='24px'>
                    <Center w='2.4em'><Icon as={FaWhatsapp} boxSize={'1.6em'} /></Center>
                    <Box w='80%'>
                      <a href={this.state.selectedItem.whatsappLink} style={{textDecoration: 'none', color: 'black'}} target="_blank">
                        <Text>{this.state.selectedItem.whatsappLink} <ExternalLinkIcon /></Text>
                      </a>
                    </Box>
                  </HStack>
                </Box>
                }

                <Box>
                  <HStack spacing='24px'>
                    <Center w='2.4em'><Icon as={FaShareAlt} boxSize={'1.6em'} /></Center>
                    <Box w='80%'>
                      <a href={global.config.shareURL + encodeURI(this.state.selectedItem.businessName)} style={{textDecoration: 'none', color: 'black'}} target="_blank">
                        <Text>{global.config.shareURL + encodeURI(this.state.selectedItem.businessName)} <ExternalLinkIcon /></Text>
                      </a>
                    </Box>
                  </HStack>
                </Box>

                <Box>
                  <Text dangerouslySetInnerHTML={{__html: 
                    this.state.selectedItem.description.replace(/\n/g, "<br />")
                  }}></Text>
                </Box>
              </VStack>              
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.onCloseItemModal}>Close</Button>
            </ModalFooter>
          </ModalContent>
          }
        </Modal>

      </React.Fragment>

    )
  }

}

export default injectTodos(RestaurantList);