import logo from './logo.svg';
import React from 'react';
import { Link } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons'

class Navbar extends React.Component {
  
  constructor(props) {
    super(props);

    this.onClickRegister = this.onClickRegister.bind(this);
    this.onShowLoginModal = this.onShowLoginModal.bind(this);
    this.onShowAboutModal = this.onShowAboutModal.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
  }

  onClickRegister(e)
  {
    e.preventDefault();
    this.props.showRegisterModal();
  }

  onShowLoginModal(e)
  {
    e.preventDefault();
    this.props.showLoginModal();
  }

  onShowAboutModal(e)
  {
    e.preventDefault();
    this.props.showAboutModal();
  }

  onSignOut(e)
  {
    e.preventDefault();
    this.props.signOut();
  }

render() {
    return (

      <React.Fragment>
      
        <nav className="navbar navbar-expand-lg navbar fixed-top  navbar-light" style={{backgroundColor: '#ffc107'}}>
          <a className="navbar-brand" href="#Welcome">
              <img src={window.location.origin + "/images/feedeasy-logo.png"} width="160" height="76" className="d-inline-block" alt="" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">                  
                  <Link className="nav-link" to="/" style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>Home</Link>
                </li>
                
                {this.props.jwtToken == '' &&
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={this.onClickRegister} style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>Sign Up</a>
                </li>
                }

                {this.props.jwtToken == '' &&
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={this.onShowLoginModal} style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>Sign In</a>
                </li>
                }

                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={this.onShowAboutModal} style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>About</a>
                </li>

                {this.props.jwtToken != '' &&
                <li className="nav-item">
                  <Link className="nav-link" to={'/addBusiness'} style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>Add Business</Link>
                </li>
                }

                {this.props.businessID != '' &&
                <li className="nav-item">                
                  <Link className="nav-link" to={'/myReviewList/' + this.props.businessID} style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>My Reviews</Link>
                </li>
                }

                {/*this.props.jwtToken != '' &&
                <li className="nav-item">                
                  <Link className="nav-link" to={'/addRecipe'}>Add Recipe</Link>
                </li>
                */}

                {/*this.props.jwtToken != '' &&
                <li className="nav-item">
                  <Link className="nav-link" to={'/myRecipeList'}>My Recipe</Link>
                </li>
                */}

                {this.props.jwtToken != '' &&
                <li className="nav-item">                
                  <Menu>
                    <MenuButton                      
                      px={2}
                      py={2}
                      transition='all 0.2s'
                      borderRadius='md'
                      borderWidth='0px'
                      _hover={{ bg: 'gray.400' }}
                      _focus={{ boxShadow: 'outline' }}
                    >
                      <span style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>Recipe <ChevronDownIcon /></span>
                    </MenuButton>
                    <MenuList>
                      <MenuItem><Link className="nav-link" to={'/addRecipe'} style={{color: '#111111',fontSize: 'larger',fontWeight: 'bolder'}}>Add Recipe</Link></MenuItem>
                      <MenuItem><Link className="nav-link" to={'/myRecipeList'} style={{color: '#111111',fontSize: 'larger',fontWeight: 'bolder'}}>My Recipe</Link></MenuItem>
                    </MenuList>
                  </Menu>
                </li>
                }

                {this.props.jwtToken != '' &&
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={this.onSignOut} style={{color: '#ffffff',fontSize: 'larger',fontWeight: 'bolder'}}>Sign Out</a>
                </li>
                }

              </ul>
          </div>
        </nav>      

      </React.Fragment>

    )
  }

}

export default Navbar;
