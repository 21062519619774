module.exports = global.config = {
    domainURL: 'https://feedeasy.com/',
    shareURL: 'https://share.feedeasy.com/',
    shareRecipeURL: 'https://share.feedeasy.com/recipe/',
    shareReviewURL: 'https://share.feedeasy.com/review/',
    awsRegion: 'eu-west-2',
    imageS3Bucket: 'feedeasy',
    bannerS3BucketPath: 'https://feedeasy.s3.eu-west-2.amazonaws.com/banners/',
    imageS3BucketPath: 'https://feedeasy.s3.eu-west-2.amazonaws.com/images/',
    recipeS3BucketPath: 'https://feedeasy.s3.eu-west-2.amazonaws.com/recipe/',
    reviewS3BucketPath: 'https://feedeasy.s3.eu-west-2.amazonaws.com/review/',
    thumbnailS3BucketPath: 'https://feedeasy.s3.eu-west-2.amazonaws.com/images-resized/',
    userPoolId: 'eu-west-2_LepH2QbEj',
    clientId: '7o3c8a0bq3gu19pp24s6genoit',
    identityPoolId: 'eu-west-2:319d8fee-d588-4399-841c-e61d5d4ffa67',
    googleApiKey: 'AIzaSyDVNA9P2Yw6YHwxC2fapdi0GaHws62SZvA',
    lambdaAddBusiness: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/addbusiness',
    lambdaGetBusinessList: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getbusiness',
    lambdaGetBusinessByEmail: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getbusinessbyemail',
    lambdaGetBusinessByName: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getbusinessbyname',
    lambdaGetRegions: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getregions',
    lambdaAddRecipe: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/addrecipe',
    lambdaAddReview: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/addreview',
    lambdaGetRecipes: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getrecipes',
    lambdaGetRecipesByEmail: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getrecipesbyemail',
    lambdaGetRecipeByID: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getrecipebyid',
    lambdaGetReviewsByBusinessName: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getreviewsbybusinessname',
    lambdaGetReviewByID: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getreviewbyid',
    lambdaGetReviewsByBusinessID: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getreviewsbybusinessid',
    lambdaAddReplyByOwner: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/addreplybyowner',
    lambdaGetReviewsByOwner: 'https://dbsg7drrfl.execute-api.eu-west-2.amazonaws.com/feedeasy/getreviewsbyowner'
};