import logo from './logo.svg';
import React from 'react';


class Footer extends React.Component {
  
  constructor(props) {
    super(props);
  }

render() {
    return (

      <React.Fragment>
      
        <div className="row main-footer" style={{paddingBottom: '20px', marginTop: '0', backgroundColor: '#ffc107'}}>
          <div className="col">
            <p className="text-center" style={{color: '#ffffff', fontWeight: 'bolder'}}>FeedEasy {new Date().getFullYear()}, info@feedeasy.com</p>
          </div>
        </div>        

      </React.Fragment>

    )
  }

}

export default Footer;
