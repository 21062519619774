import './config';
import logo from './logo.svg';
import React, {useEffect} from 'react';
import {
  Button,
  Grid, 
  GridItem,
  Flex,
  Box,
  Center,
  Text,
  Input,
  Stack,
  VStack,
  StackDivider,
  Textarea,
  InputGroup,
  InputLeftElement,
  Heading,
  Select,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner
} from '@chakra-ui/react';

import {
  DeleteIcon
} from '@chakra-ui/icons';

import ReactArticleEditor from './react-article-editor/ReactArticleEditor';
import ReactImageUploadComponent from './react-images-upload/ReactImageUploadComponent';
import ImageGallery from 'react-image-gallery';
import AWS from 'aws-sdk/dist/aws-sdk-react-native';
import md5 from 'md5';
import "react-image-gallery/styles/css/image-gallery.css";
import axios from 'axios';
import { Loader } from '@googlemaps/js-api-loader';
import { isJwtExpired } from 'jwt-check-expiration';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const googleAPILoader = new Loader({
  apiKey: global.config.googleApiKey,
  version: "weekly",
  libraries: ["places"]
});

function injectTodos(Component) {
  const InjectedTodos = function (props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
  return InjectedTodos;
}

class AddRecipe extends React.Component {
  
  constructor(props) {
    super(props);

    AWS.config.update({
      region: 'eu-west-2',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: global.config.identityPoolId
      })
    });

    this.state = {
      jwtToken: '',
      isSaving: false,
      isTitleGiven: false,
      isFirstTextGiven: false,
    };

    this.defaultContent = {
      hasTitle: true,
      paragraphs:[
        {"type":1,"content":""},
        {"type":2}
      ],
    };

    this.onTitleUpdate = this.onTitleUpdate.bind(this);
    this.onTextParagraphUpdate = this.onTextParagraphUpdate.bind(this);
    this.onTextParagraphDelete = this.onTextParagraphDelete.bind(this);
    this.onAdd = this.onAdd.bind(this);

    this.editor = React.createRef();
  }

  componentDidMount()
  {    
    if(Cookies.get('jwtToken') != undefined)
    {
      let jwtToken = Cookies.get('jwtToken');
      console.log('token: ' + jwtToken);

      if(!isJwtExpired(jwtToken))
      {
        this.setState({jwtToken: jwtToken});        
      }
      else
      {
        Cookies.remove('jwtToken');
        this.setState({jwtToken: ''});

        window.location.href = '/';
        //this.props.navigate('/');
      }
    }
    else
    {
      Cookies.remove('jwtToken');
      this.setState({jwtToken: ''});      

      window.location.href = '/';
      //this.props.navigate('/');
    }    
  }

  getObjectValue(item, property, def)
  {
    if(item.hasOwnProperty(property)) 
      return item[property];
    else 
      return def;
  }  

  onTitleUpdate(original, newValue)
  {
    if(newValue == '')
      this.setState({isTitleGiven: false});
    else
      this.setState({isTitleGiven: true});
  }

  onTextParagraphUpdate(previousParagraphs, newParagraphs, index)
  {
    if(newParagraphs[index].content != '')
      this.setState({isFirstTextGiven: true});
    else
    {
      let flag = false;
      for(let i = 0; i < newParagraphs.length; i++)
      {
        let paragraph = newParagraphs[i];
        if(paragraph.type == 1 && paragraph.content != '')
        {
          flag = true;
          break;
        }
      }

      if(!flag)
        this.setState({isFirstTextGiven: false});
    }
  }

  onTextParagraphDelete(previousParagraphs, newParagraphs, index)
  {
    if(newParagraphs.length == 0)
    {
      this.setState({isFirstTextGiven: false});
    }
    else
    {
      let flag = false;
      for(let i = 0; i < newParagraphs.length; i++)
      {
        let paragraph = newParagraphs[i];
        if(paragraph.type == 1 && paragraph.content != '')
        {
          flag = true;
          break;
        }
      }

      if(!flag)
        this.setState({isFirstTextGiven: false});
    }
  }

  async onAdd()
  {
    this.setState({isSaving: true});    
    
    let rawContent = this.editor.current.getComposition();
    let paragraphs = rawContent.paragraphs;

    let content = {
      title: rawContent.title,
      paragraphs: []
    };

    let isFirstImage = true;
    for(let i = 0; i < paragraphs.length; i++)
    {
      let paragraph = paragraphs[i];

      if(paragraph.type == 1)
      {
        if(paragraph.content != '')
          content.paragraphs.push(paragraph);
      }
      else if(paragraph.type == 2 && paragraph.file)
      {
        if(paragraph.hasOwnProperty('image') && paragraph.image != '')
        {
          content.paragraphs.push(paragraph);
        }
        else if(paragraph.file)
        {
          let noresize = true;
          if(isFirstImage)
            noresize = false;

          try
          {
            var data = await this.uploadToS3(paragraph.file, 'recipe/', noresize);
            console.log(data);
          }
          catch(e)
          {
            console.log('failed to upload file');
            console.log(e);

            i--;
            continue;
          }

          delete paragraph.file;
          delete paragraph.base64;
          delete paragraph.defaultImages;

          if(isFirstImage)
          {
            content.thumbnail = data.fileName;
            console.log(data.fileName);
            isFirstImage = false;
          }

          paragraph.image = data.fileName;
          content.paragraphs.push(paragraph);
        }
      }
    }

    console.log(JSON.stringify(content));

    var postData = {
      content: JSON.stringify(content)
    };
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': this.state.jwtToken
      }
    };

    console.log(postData);

    axios.post(global.config.lambdaAddRecipe, postData, axiosConfig)
    .then((res) => {

      var data = res['data'];
      if(data && data['result'])
      {
        this.editor.current.reset();
        this.setState({isSaving: false});
      }

    })
    .catch((err) => {
      console.log("AXIOS ERROR: " + JSON.stringify(err));

      this.setState({isSaving: false});
    });       
  }

  uploadToS3(file, folder, noresize)
  {
    
    return new Promise((resolve, reject)=>{
      
      if(file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/webp')
      {
        var fileType = 'png';
        if(file.type == 'image/jpeg')
          fileType = 'jpg';
        else if(file.type == 'image/webp')
          fileType = 'webp';

        var prefix = '';
        if(noresize)
          prefix = 'noresize-';

        var fileName = prefix + md5(Date.now() + '-' + file.name) + '.' + fileType;
        var fullPath = folder + fileName;

        // Use S3 ManagedUpload class as it supports multipart uploads
        
        var upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: 'feedeasy',
            Key: fullPath,
            Body: file
          }
        });
      
        var promise = upload.promise().then(
          (data) => {
            //alert("Successfully uploaded photo.");
            data['fileName'] = fileName;
            resolve(data);            

          },
          (err) => {
            
            console.log('upload error');
            console.log(JSON.stringify(err));
            reject(err);

          }
        );
                
      }
      else
      {
        reject({reason: 'file type not supported'});
      }

    });
    
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////


  render() {
    return (

      <React.Fragment>
        
        <div className="container">
        
          <div className="row" style={{paddingTop: '4rem', paddingBottom: '4rem'}}>
            <div className="col navMenu">
              <Center><Text fontSize='2xl'>~Add Recipe~</Text></Center>
            </div>
          </div>
          
          
          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">

              <ReactArticleEditor 
                editor={this.editor} 
                defaultContent={this.defaultContent} 
                imageURL={global.config.recipeS3BucketPath} 
                onTitleUpdate={this.onTitleUpdate} 
                onTextParagraphUpdate={this.onTextParagraphUpdate}
                onTextParagraphDelete={this.onTextParagraphDelete}
                //onTextParagraphAdd={(previousParagraphs, newParagraphs, index)=>{}}
                //onImageParagraphAdd={(previousParagraphs, newParagraphs, index)=>{}}
                //onTextParagraphDelete={(previousParagraphs, newParagraphs, index)=>{}}
                //onImageParagraphDelete={(previousParagraphs, newParagraphs, index)=>{}}
                //onImageAdd={(previousParagraphs, newParagraphs, index)=>{}}
                //onImageDelete={(files, pictures, removeIndex)=>{}}
                isSingleFile={true}
              />

            </div>
          </div>

          <div className="row" style={{paddingBottom: '2rem'}}>
            <div className="form-group col-12">
              <Text align='center'>
                <Button colorScheme='green' onClick={this.onAdd} width='100%' isDisabled={!this.state.isTitleGiven || !this.state.isFirstTextGiven}>Add</Button>
              </Text>            
            </div>          
          </div>          
        
        </div>

        <Modal isCentered isOpen={this.state.isSaving}> size={'xs'}
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'
          />
          <ModalContent>
            <ModalHeader></ModalHeader>            
            <ModalBody>
              <VStack spacing='24px'>
              <Center><Spinner
                  thickness='3px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='yellow.300'
                  size='xl'
                />
              </Center>
              <Center><Text color='gray.500'>Saving...</Text></Center>
              </VStack>
            </ModalBody>
            <ModalFooter>              
            </ModalFooter>
          </ModalContent>
        </Modal>

      </React.Fragment>

    )
  }

}

export default injectTodos(AddRecipe);
