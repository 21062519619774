import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import RestaurantList from './RestaurantList';
import AddEntity from './AddEntity';
import AddRecipe from './AddRecipe';
import MyRecipeList from './MyRecipeList';
import MyReviewList from './MyReviewList';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<RestaurantList />} />
          <Route path="addBusiness" element={<AddEntity />} />
          <Route path="addRecipe" element={<AddRecipe />} />
          <Route path="myRecipeList" element={<MyRecipeList />} />
          <Route path="myReviewList/:businessID" element={<MyReviewList />} />
        </Route>
        <Route path="/:region" element={<App />}>
          <Route index element={<RestaurantList />} />
          <Route path="addBusiness" element={<AddEntity />} />
          <Route path="addRecipe" element={<AddRecipe />} />
          <Route path="myRecipeList" element={<MyRecipeList />} />
        </Route>
      </Routes>
    </BrowserRouter>

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
